import React from 'react'
import ImageGrid from './ImageGrid'
import InfoDesc from './InfoDesc'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import store from '../../../redux/store'
import { applyFilters, setInitialized, resetAppliedFilters } from '../../../redux/actions/objects'
import { filtersBase } from '../../../utils/filters'


const langText = {
  'es': {
    btnTitle: 'Ver Todos',
    btnSubtitle: 'actuales'
  },
  'en': {
    btnTitle: 'See all',
    btnSubtitle: 'participants'
  }
}

export default class Participant extends React.Component {

  state = {
    active: 'main_tutors'
  }

  onChangeItems = () => {
    let { active } = this.state
      , switchLabel = active === 'participants' ? 'main_tutors' : 'participants'
    this.setState({ active: switchLabel })
  }


  onClick = (filters, history) => {
    store.dispatch(resetAppliedFilters())
    store.dispatch(applyFilters({ ...filtersBase, ...filters }))
    store.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  render() {

    let { appState } = this.props
      , { active } = this.state
      , columnSize = appState.itemSize
      , data = this.props[active]
      , items = this.props[active].items
        .map(item => ({ ...item, rand: Math.random() }))
        .sort((a, b) => a.value - b.value)
      , year = (new Date()).getFullYear()
      , personList = this.props[active].items.map(item => ({ ...item, title: item.person, person: item.title, date: '' }))
      , lang = langText[appState.lang]

    items = items.map(item => ({ ...item, date: '' }))

    if (appState.item === 6) items = items.filter((_, i) => i < 5)
    if (appState.item === 4) items = items.filter((_, i) => i < 3)
    if (appState.item === 2) items = items.filter((_, i) => i < 3)

    if (appState.item === 2) return <div id='equipo-docente'>
      <div className='column no-top' style={{ height: (columnSize * 4) }}>
        <div className='row no-top' style={{ minHeight: (columnSize * 2) }}>
          <InfoDesc {...this.props} fullDescription={{ onChange: this.onChangeItems, title: data.title, paragraph: data.description }} />
        </div>
        <div className='row no-top' style={{ minHeight: (columnSize * 2) }}>
          <ImageGrid {...this.props} position='right' banner={data.banner} />
        </div>
      </div>
      <div className='row no-top' style={{ flexWrap: 'wrap' }}>
        {
          items
            .map((item, index) => <Link to={`${getUrl('objectsPerson')}?id=${item.id}`}
              className={`item item-button column goal-border ${index % 2 === 0 ? 'no-left' : 'no-right'}`} key={index}
              style={{ maxWidth: '50%', width: columnSize, height: columnSize, }}
            >
              <div>
                <h2>{item.person}</h2>
                <h4>{item.title}</h4>
              </div>
              <div className='item-action-button'>
                <p className='font-button'>{item.date}</p>
                <span className="icon-search-05"></span>
              </div>
            </Link>)
        }
        <div className='item item-button goal-border no-left' onClick={() => this.props.onShowList(personList, data.title)} style={{ maxWidth: '50%', height: columnSize - 2, background: appState.colorTheme.secondary, width: columnSize, borderBottom: '2px solid black', borderLeft: '2px solid black' }} >
          <div>
            <h2>{lang.btnTitle}</h2>
            <h4>{year}</h4>
          </div>
          <div className='item-action-button'>
            <p> </p>
            <span className="icon-search-03"></span>
          </div>
        </div>
      </div>
    </div>

    return <div id='equipo-docente'>
      <div className='row no-top' style={{ height: (columnSize * 2) - 2 }}>

        <ImageGrid {...this.props} position='right' banner={data.banner} />
        <div style={{ height: columnSize * 2, width: 4, background: 'black' }} />
        <InfoDesc {...this.props} fullDescription={{ onChange: this.onChangeItems, title: data.title, paragraph: data.description }} />
      </div>
      <div className='row' style={{ height: (columnSize) }}>
        {
          items.map((item, index) => <Link to={`${getUrl('objectsPerson')}?id=${item.id}`}
            data-title={item.person}
            className={`item item-button column ${index === 0 ? 'no-left' : ''}`} key={index}
            style={{ width: columnSize + 2, height: columnSize, }}
          >
            <div>
              <h2>{item.person}</h2>
              <h4>{item.title}</h4>
            </div>
            <div className='item-action-button'>
              <p className='font-button'>{item.date}</p>
              <span className="icon-search-05"></span>
            </div>
          </Link>)
        }
        <div className='item item-button' data-title={lang.btnTitle} onClick={() => this.props.onShowList(personList, data.title)} style={{ height: columnSize - 2, background: appState.colorTheme.secondary, width: columnSize, borderLeft: '1px solid black' }} >
          <div>
            <h2>{lang.btnTitle}</h2>
            <h4>{year}</h4>
          </div>
          <div className='item-action-button'>
            <p> </p>
            <span className="icon-search-03"></span>
          </div>
        </div>
      </div>
    </div>
  }
}

// style={{ width: columnSize, height: columnSize - 2, borderRight: '2px solid black' }} 
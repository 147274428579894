import React from 'react'
import { connect } from 'react-redux'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Hero from './components/Hero'
import Text from './components/Text'
import getTemplate from './template'
import InfoDesc from './components/InfoDesc'
import InfoBox from './components/InfoBox'
import AnnouncementBlock from './components/AnnouncementBlock'
import ShowRoom from './components/ShowRoom'
import Exhibition from './components/Exhibition'
import Participants from './components/Participants'
import Tutors from './components/Tutors'
import ImageGrid from './components/ImageGrid'
import AskBox from './components/AskBox'
import Loading from '../../baseComponents/Loading'
import Objectives from './components/Objetives'
import Newsletter from '../../baseComponents/Newsletter'
import ContactProgram from '../../baseComponents/ContactProgram'
import ItemInfo from './components/ItemInfo'
import SearchLayout from '../../baseComponents/SearchLayout'
import HeaderWithBack from '../../baseComponents/HeaderWithBack'

import { getUrlByType, getUrl } from '../../utils/routesDefinition'
import { Link } from 'react-router-dom'

import { pesProgramResources } from '../../services/pageResources'

import './PESScreen.css'

class PESScreen extends React.Component {

  state = {
    template: [],
    resources: null,
    isFetching: true,
    isFetchingEnd: false,
    error: null,
    showItemList: false,
    itemList: [],
    itemListTItle: '',
    showParticipants: false,
    participants: [],
    participantsTitle: '',
    years: [],
    year: new Date().getFullYear(),
    activeParticipants: false,
  }

  onActiveSearch = () => {
  }

  getComponent = (component, props) => {
    let { resources } = this.state
    if (component === null) return component
    switch (component) {
      case 'ItemInfo': return <ItemInfo {...props} />
      case 'Hero': return <Hero {...props} {...resources} />
      case 'Text': return <Text {...props} />
      case 'InfoBox': return <InfoBox {...props} {...resources} />
      case 'InfoDesc': return <InfoDesc {...props} {...resources} />
      case 'AnnouncementBlock': return <AnnouncementBlock {...props} {...resources} />
      case 'ImageGrid': return <ImageGrid {...props} />
      case 'AskBox': return <AskBox {...props} {...resources} />
      case 'ShowRoom': return <ShowRoom {...props} {...resources} onShowList={() => this.onGoToItems('cuarto-de-proyectos')} />
      case 'Exhibition': return <Exhibition {...props} {...resources} onShowList={() => this.onGoToItems('proyecto-final')} />
      case 'Participants': return <Participants {...props} {...resources}
        onShowParticipants={() => this.onGoToItems('participantes')}
        onShowList={this.onShowList} />
      case 'Tutors': return <Tutors {...props} {...resources} onShowList={() => this.onGoToItems('equipo-docente')} />
      case 'Objectives': return <Objectives {...props} {...resources} />
      case 'Newsletter': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <Newsletter color={color} />
      }
      case 'ContactProgram': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <ContactProgram color={color} />
      }
      default: return null
    }
  }

  onGoToItems = (type) => {
    this.props.history.push(`${getUrl('pes')}/${type}`)
  }

  onGoBack = () => {
    this.setState({ showItemList: false, itemList: [], itemListTItle: '', showParticipants: false })
  }

  onSeeMoreHandler = () => {
  }

  onShowList = (items, title) => {
    let years = {}
    for (let index = 0; index < items.length; index++) {
      let item = items[index]
      let year = item.year
        ? item.year
        : new Date(item.startDate).getFullYear()
      if (year) {
        years[year] = year
        items[index].year = year
      }
    }
    years = Object.keys(years).sort((a, b) => b - a)
    this.setState({
      itemList: items,
      showItemList: true, itemListTItle: title,
      years: years,
      year: years[0]
    })
  }

  onShowParticipants = (items, title, years) => {
    if (!years) {
      years = {}
      for (let index = 0; index < items.length; index++) {
        let year = items[index].year
        if (year) years[year] = year
      }
      years = Object.keys(years)
    }
    this.setState({ participants: items, showParticipants: true, participantsTitle: title, years, year: years[0] })
  }

  getData = () => {
    pesProgramResources()
      .then(res => {
        const resources = res.data
        resources.projects_room.items = resources.projects_room.items.map(item => ({ ...item, title: item.title.length > 20 ? `${item.title.substring(0, 17)}...` : item.title }))

        this.setState({ isFetchingEnd: true, resources }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false }, () => {
              setTimeout(() => {
                let lastSection = localStorage.getItem('lastSection')
                if (lastSection) {
                  lastSection = JSON.parse(lastSection)
                  let element = document.getElementById(lastSection.section)
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                    setTimeout(() => {
                      element.scrollIntoView({ behavior: 'smooth' })
                      localStorage.removeItem('lastSection')
                    }, 500);
                  }
                }
              }, 500)
            })
          }, 800)
        })
      })
      .catch(err => console.log('error', err))
    this.setState({ template: getTemplate()[this.props.appState.item] })
  }


  componentDidMount() {
    this.getData()
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.item !== nextProps.appState.item) {
      this.setState({ template: getTemplate()[nextProps.appState.item] })
    }
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        template: [],
        resources: null,
        isFetching: true,
        isFetchingEnd: false,
        error: null,
        showItemList: false,
        itemList: [],
        itemListTItle: ''
      }, this.getData)
    }
  }

  /*
  
  <a className="row no-top no-bottom" href="/archivo/persona?id=2115" style="height: 100%;"><div className="background image-cover-bg" style="background: url(&quot;/static/media/patron_noimage.8400ac91.png&quot;); width: 241px;"><div className="background image-cover-bg" style="background: url(&quot;https://somamexico.org/storage/persons/December2019/GjXpqi8945eR6snzwY1l.png&quot;); width: 241px;"></div></div><div className="item item-button item-info column no-right" data-title="Verónica Gerber" style="width: 241px;"><div><h2>Verónica Gerber</h2><h4></h4></div><div className="item-action-button"><p>1981</p><span className="icon-search-05"></span></div></div></a>
  */


  render() {
    let { isFetching, isFetchingEnd, template, showItemList, itemList, itemListTItle, showParticipants, participants, participantsTitle, year, years, activeParticipants } = this.state
    if (isFetching) return <Loading isOut={isFetchingEnd} />
    if (showParticipants) return <div id='pes' className='header-participants'>
      <HeaderWithBack title={participantsTitle} onGoBack={this.onGoBack} />
      <div id="main" style={{ marginTop: `${0}px` }} >
        <Grid fullmode>
          <div values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }} />
          <div className='content-block' values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }}>
            <div className='item-button-width-end item-button-border centered' style={{ height: this.props.appState.itemSize }}>
              <div className='year-selector'>
                <div className='year-selector-data' onClick={() => { this.setState(prevState => ({ activeParticipants: !prevState.activeParticipants })) }}>
                  <p>{year}</p>
                  <span className={`icon-search-05 ${activeParticipants ? 'active' : ''}`} />
                </div>
                {
                  activeParticipants
                    ? <div className='year-options' style={{ background: this.props.appState.colorTheme.base, height: this.props.appState.itemSize * 1.25 }}>
                      {years.map(y => <p onClick={e => { e.stopPropagation(); this.setState({ year: Number(y), activeParticipants: false, }) }} key={y}>{y}</p>)}
                    </div>
                    : null
                }
              </div>
            </div>
            {
              participants
                .filter(p => Number(p.year) === Number(year))
                .map(p => <div className='item-button-width-end item-button-border' style={{ height: this.props.appState.itemSize }}>
                  <Link to={`${getUrlByType(p.objectType)}?id=${p.id}`} data-title={p.title} className='item item-button item-info'>
                    <div>
                      <h2>{p.title}</h2>
                      <h4>{p.person}</h4>
                    </div>
                    <div className='item-action-button'>
                      <p>{p.date}</p>
                      <span className="icon-search-05"></span>
                    </div>
                  </Link>
                </div>)
            }
          </div>
        </Grid>
      </div>
    </div>

    if (showItemList) return <div id='pes' className='header-participants'>
      <HeaderWithBack title={itemListTItle} onGoBack={this.onGoBack} />
      <div id="main" style={{ marginTop: `${0}px` }} >
        <Grid fullmode>
          <div values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }} />
          <div className='content-block' values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }}>
            <div className='item-button-width-end item-button-border centered' style={{ height: this.props.appState.itemSize }}>
              <div className='year-selector'>
                <div className='year-selector-data' onClick={() => { this.setState(prevState => ({ activeParticipants: !prevState.activeParticipants })) }}>
                  <p>{year}</p>
                  <span className={`icon-search-05 ${activeParticipants ? 'active' : ''}`} />
                </div>
                {
                  activeParticipants
                    ? <div className='year-options' style={{ background: this.props.appState.colorTheme.base, height: this.props.appState.itemSize * 1.25 }}>
                      {years.map(y => <p onClick={e => { e.stopPropagation(); this.setState({ year: Number(y), activeParticipants: false, }) }} key={y}>{y}</p>)}
                    </div>
                    : null
                }
              </div>
            </div>
            {
              itemList
                .filter(p => Number(p.year) === Number(year))
                .map(p => <div className='item-button-width-end item-button-border' style={{ height: this.props.appState.itemSize }}>
                  <Link to={`${getUrlByType(p.objectType)}?id=${p.id}`} data-title={p.title} className='item item-button item-info'>
                    <div>
                      <h2>{p.title}</h2>
                      <h4>{p.person}</h4>
                    </div>
                    <div className='item-action-button'>
                      <p>{p.date}</p>
                      <span className="icon-search-05"></span>
                    </div>
                  </Link>
                </div>)
            }
          </div>
        </Grid>
      </div>
    </div>

    return <div id='pes'>
      <SearchLayout header={<Header />}>
        <div id="main" style={{ marginTop: `${0}px` }} >
          <Grid>
            {
              template.map((item, index) => {
                let props = { ...this.props, ...this.state.settings, ...item }
                //if (item.component === 'InfoDesc') props.onSeeMoreHandler = this.onSeeMoreHandler
                return <div
                  key={index}
                  values={{ x: item.x, y: item.y, w: item.w, h: item.h, }}
                >
                  {this.getComponent(item.component, props)}
                </div>
              })
            }
          </Grid>
        </div>
      </SearchLayout>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(PESScreen)

import React from 'react'

const google = window.google

export default class Map extends React.Component {

  componentDidMount() {
    let marker, m68Lat
    m68Lat = new google.maps.LatLng(19.390636, -99.185524)
    var mapOptions = {
      zoom: 16,
      center: m68Lat,
      panControl: false,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      scrollWheel: false,
    }
    let map = new google.maps.Map(document.getElementById('soma-map'), mapOptions);
    marker = new google.maps.Marker({
      position: m68Lat,
      title: "SOMA",
    })

    // let infoMaker = "SOMA"
    // let infowindowMarker = new google.maps.InfoWindow({ content: infoMaker })
    var styles = [
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#536fa9"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#5977ba"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#445c8c"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#5a79b8"
          },
          {
            "lightness": 0
          },
          {
            "weight": 0.2
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#445c8c"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#6181c2"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#536fa9"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#536fa9"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "off"
          },
          {
            "color": "#5977ba"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "saturation": 90
          },
          {
            "color": "#161e2d"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#5977ba"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#5977ba"
          },
          {
            "lightness": 0
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#5977ba"
          },
          {
            "lightness": 0
          },
          {
            "weight": 1.2
          }
        ]
      }
    ]
    /* google.maps.event.addListener(marker, 'click', () => { infowindowMarker.open(map, marker) })
    infowindowMarker.open(map, marker) */
    marker.setMap(map)
    map.setOptions({ styles: styles })

  }

  render() {
    return <div id='soma-map' style={{ height: '100%', width: '100%' }} />
  }
}
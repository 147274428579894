import React from 'react'
import getTexts from '../../../services/langTexts'
import { css } from 'glamor'
import Selector from './Selector'

export default class Councils extends React.Component {

  state = {
    social_service: {},
    social_service_year: (new Date()).getFullYear(),
    isActive: false,
    displayReports: false,
  }

  onActiveSelector = () => this.setState(prevState => ({ isActive: !prevState.isActive }))


  setYear = social_service_year => this.setState({ social_service_year, isActive: false })

  componentDidMount() {
    let year = 0
      , social_service_items = {}
      , social_service = this.props.councils
        .filter(council => council.type === 'social_service')
        .sort((a, b) => a.year - b.year)
    year = social_service[social_service.length - 1].year
    for (let index = 0; index < social_service.length; index++) social_service_items[social_service[index].year] = { ...social_service[index] }
    this.setState({ social_service: social_service_items, social_service_year: year })
  }

  render() {
    let { appState, councils, fullDescription, reports } = this.props
      , { social_service, social_service_year, isActive } = this.state
      , years = Object.keys(social_service).filter(year => Number(year) !== Number(social_service_year))
      , texts = getTexts('about-screen', appState.lang)
      , itemHover = css({
        ':hover': { background: appState.colorTheme.secondary },
      })

    if (appState.item === 6) return <div className='column'>
      <div className='column council-block no-top'>
        <h2>{texts.council.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[0].council_text }} />
          </div>
          <div className=' council-container' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[11].council_text === 'string'
                ? councils[11].council_text.replace(/style="[^"]*"/g, '')
                : councils[11].council_text
            }} />
          </div>
          <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[1].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[1].council_text }} />
          </div>
          <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[2].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[2].council_text === 'string'
                ? councils[2].council_text.replace(/style="[^"]*"/g, '')
                : councils[2].council_text
            }} />
          </div>
        </div>
      </div>

      <div className='column council-block no-top' style={{ padding: 0, }}>
        <div className='row'>
          <div className='column council-block no-top' >
            <h2>{texts.support.toUpperCase()}</h2>
            <div className='block-container'>
              <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[6].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[6].council_text }} />
                {/* <h3 className='margin-top'>{councils[9].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[8].council_text }} /> */}
                <h3 className='margin-top'>{councils[3].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[3].council_text }} />
              </div>
              <div className=' council-container' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[13].council_title}</h3>
                <div dangerouslySetInnerHTML={{
                  __html: typeof councils[13].council_text === 'string'
                    ? councils[13].council_text.replace(/style="[^"]*"/g, '')
                    : councils[13].council_text
                }} />
              </div>
              <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[4].council_title}</h3>
                <div dangerouslySetInnerHTML={{ __html: councils[4].council_text }} />
              </div>
            </div>
          </div>
          <div className='council-buttons-container' style={{ width: appState.itemSize }}>
            <div className='item-box' />
            <div className='item-box button reports-button'
              style={{ background: appState.colorTheme.secondary, height: appState.itemSize, maxHeight: appState.itemSize }}
            >
              <h2>{fullDescription.download_btn.title}</h2>
              <div className='cursor' onClick={() => this.setState(prevState => ({ displayReports: !prevState.displayReports }))}>
                <div className='title'>
                  <p>{fullDescription.download_btn.subtitle}</p>
                  <span className={`icon-search-05 bottom ${this.state.displayReports ? 'rotate' : ''}`} />
                </div>
                {
                  this.state.displayReports &&
                  <Selector
                    onClick={() => this.setState({ displayReports: false })}
                    items={reports}
                    style={{ background: appState.colorTheme.level1 }}
                  />
                }
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='column no-left no-right' style={{ height: '50%' }}>
        <div className='column council-block no-top'>
          <h3 className='margin-top'>{councils[9].council_title}</h3>
          <div className='row no-top no-bottom council-logos' dangerouslySetInnerHTML={{ __html: councils[9].council_text }} />
        </div>
      </div>


      <div className='column council-block no-top'>
        <h2>{texts.team.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[10].council_title}</h3>
            <div className='padding-text' dangerouslySetInnerHTML={{ __html: councils[10].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[5].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[5].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), paddingLeft: '2vw', width: '33%' }}>
            {
              social_service[social_service_year]
                ? <React.Fragment>
                  <h3>{social_service[social_service_year].council_title}</h3>
                  <div style={{ position: 'relative', cursor: 'pointer' }}>
                    <div onClick={this.onActiveSelector} className='year-selector'>
                      <p>{social_service_year}</p>
                      <span className='icon-search-05 top' />
                    </div>
                    {
                      isActive
                        ? <div className='year-option-container' style={{ background: appState.colorTheme.base }}>
                          {years.map(year => <p {...itemHover} key={year} onClick={() => this.setYear(year)}>{year}</p>)}
                        </div>
                        : null
                    }
                  </div>
                  <div className='padding-text' dangerouslySetInnerHTML={{ __html: social_service[social_service_year].council_text }} />
                </React.Fragment>
                : null
            }
          </div>
        </div>
      </div>
    </div >


    if (appState.item === 4) return <div className='column'>
      <div className='column council-block no-top'>
        <h2>{texts.council.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[0].council_text }} />
          </div>
          <div className=' council-container' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[11].council_text === 'string'
                ? councils[11].council_text.replace(/style="[^"]*"/g, '')
                : councils[11].council_text
            }} />
          </div>
          <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[1].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[1].council_text }} />
          </div>
          <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[2].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[2].council_text === 'string'
                ? councils[2].council_text.replace(/style="[^"]*"/g, '')
                : councils[2].council_text
            }} />
          </div>
        </div>
      </div>

      <div className='column council-block no-top' style={{ padding: 0, }}>
        <div className='row'>
          <div className='column council-block no-top'>
            <h2>{texts.support.toUpperCase()}</h2>
            <div className='block-container'>
              <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[6].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[6].council_text }} />
                {/*  <h3>{councils[9].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[7].council_text }} /> */}
                <div style={{ marginBottom: '1vh' }} />
                <h3>{councils[3].council_title}</h3>
                <div className='' dangerouslySetInnerHTML={{ __html: councils[3].council_text }} />
              </div>
              <div className=' council-container' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[13].council_title}</h3>
                <div dangerouslySetInnerHTML={{
                  __html: typeof councils[13].council_text === 'string'
                    ? councils[13].council_text.replace(/style="[^"]*"/g, '')
                    : councils[13].council_text
                }} />
              </div>
              <div className=' council-container no-right' style={{ maxHeight: (appState.itemSize * 3), }}>
                <h3>{councils[4].council_title}</h3>
                <div dangerouslySetInnerHTML={{ __html: councils[4].council_text }} />
              </div>
            </div>
          </div>
          <div className='council-buttons-container' style={{ minWidth: appState.itemSize, }}>
            <div className='item-box' />
            <div className='item-box button reports-button'
              style={{ background: appState.colorTheme.secondary, height: appState.itemSize, maxHeight: appState.itemSize }}
            >
              <h2>{fullDescription.download_btn.title}</h2>
              <div className='cursor' onClick={() => this.setState(prevState => ({ displayReports: !prevState.displayReports }))}>
                <div className='title'>
                  <p>{fullDescription.download_btn.subtitle}</p>
                  <span className={`icon-search-05 bottom ${this.state.displayReports ? 'rotate' : ''}`} />
                </div>
                {
                  this.state.displayReports &&
                  <Selector
                    onClick={() => this.setState({ displayReports: false })}
                    items={reports}
                    style={{ background: appState.colorTheme.level1 }}
                  />
                }
              </div>
            </div>
          </div>
        </div>

      </div>


      <div className='column no-left no-right' style={{ height: '50%' }}>
        <div className='column council-block no-top'>
          <h3 className='margin-top'>{councils[9].council_title}</h3>
          <div className='row no-top no-bottom council-logos' dangerouslySetInnerHTML={{ __html: councils[9].council_text }} />
        </div>
      </div>
      <div className='column council-block no-top'>
        <h2>{texts.team.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[10].council_title}</h3>
            <div className='padding-text' dangerouslySetInnerHTML={{ __html: councils[11].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[5].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[5].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), paddingLeft: '2vw', width: '33%' }}>
            {
              social_service[social_service_year]
                ? <React.Fragment>
                  <h3>{social_service[social_service_year].council_title}</h3>
                  <div style={{ position: 'relative', cursor: 'pointer' }}>
                    <div onClick={this.onActiveSelector} className='year-selector'>
                      <p>{social_service_year}</p>
                      <span className='icon-search-05 top' />
                    </div>
                    {
                      isActive
                        ? <div className='year-option-container' style={{ background: appState.colorTheme.base }}>
                          {years.map(year => <p key={year} onClick={() => this.setYear(year)}>{year}</p>)}
                        </div>
                        : null
                    }
                  </div>
                  <div className='padding-text' dangerouslySetInnerHTML={{ __html: social_service[social_service_year].council_text }} />
                </React.Fragment>
                : null
            }
          </div>
        </div>
      </div>
    </div>

    return <div className='column'>
      <div className='column council-block no-top no-bottom' >
        <h2>{texts.council.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' >
            <h3>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[0].council_text }} />
          </div>
          <div className=' council-container'>
            <h3 className='margin-top'>{councils[0].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[11].council_text === 'string'
                ? councils[11].council_text.replace(/style="[^"]*"/g, '')
                : councils[11].council_text
            }} />
          </div>
        </div>
      </div>
      <div className='column council-block no-top'>
        <div className='block-container' >
          <div className=' council-container no-right'>
            <h3>{councils[1].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[1].council_text }} />
          </div>
          <div className=' council-container no-right'>
            <h3 className='margin-top'>{councils[2].council_title}</h3>
            <div dangerouslySetInnerHTML={{
              __html: typeof councils[2].council_text === 'string'
                ? councils[2].council_text.replace(/style="[^"]*"/g, '')
                : councils[2].council_text
            }} />
          </div>
        </div>
      </div>

      <div className='row no-top no-bottom' >
        <div className='column council-block no-top no-bottom'>
          <h2>{texts.support.toUpperCase()}</h2>
          <div className='block-container'>
            <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), width: '50%' }}>
              <h3>{councils[6].council_title}</h3>
              <div className='' dangerouslySetInnerHTML={{ __html: councils[6].council_text }} />
              {/* <h3 className='margin-top'>{councils[9].council_title}</h3>
              <div className='' dangerouslySetInnerHTML={{ __html: councils[7].council_text }} /> */}
              <h3 className='margin-top'>{councils[3].council_title}</h3>
              <div className='' dangerouslySetInnerHTML={{ __html: councils[3].council_text }} />
            </div>
            <div className=' council-container' style={{ maxHeight: (appState.itemSize * 3), width: '50%' }}>
              <h3>{councils[13].council_title}</h3>
              <div dangerouslySetInnerHTML={{
                __html: typeof councils[13].council_text === 'string'
                  ? councils[13].council_text.replace(/style="[^"]*"/g, '')
                  : councils[13].council_text
              }} />
            </div>
          </div>
        </div>
      </div>
      <div className='row no-top no-bottom' style={{ maxHeight: appState.itemSize }} >
        <div className='column council-block no-top no-right' style={{ justifyContent: 'flex-start', padding: '3vw' }}>
          <div className='block-container'>
            <div className=' council-container no-right' >
              <h3>{councils[4].council_title}</h3>
              <div dangerouslySetInnerHTML={{ __html: councils[4].council_text }} />
            </div>
          </div>
        </div>
        <div className='council-buttons-container' style={{ width: appState.itemSize, }}>
          <div className='item-box button reports-button'
            style={{ background: appState.colorTheme.secondary, height: appState.itemSize, maxHeight: appState.itemSize }}
          >
            <h2>{fullDescription.download_btn.title}</h2>
            <div className='cursor' onClick={() => this.setState(prevState => ({ displayReports: !prevState.displayReports }))}>
              <div className='title'>
                <p>{fullDescription.download_btn.subtitle}</p>
                <span className={`icon-search-05 bottom ${this.state.displayReports ? 'rotate' : ''}`} />
              </div>
              {
                this.state.displayReports &&
                <Selector
                  onClick={() => this.setState({ displayReports: false })}
                  items={reports}
                  style={{ background: appState.colorTheme.level1 }}
                />
              }
            </div>
          </div>
        </div>
      </div>

      <div className='column no-left no-right'>
        <div className='column council-block no-top'>
          <h3 className='margin-top'>{councils[9].council_title}</h3>
          <div className='row no-top no-bottom council-logos' style={{ flexWrap: 'wrap' }} dangerouslySetInnerHTML={{ __html: councils[9].council_text }} />
        </div>
      </div>

      <div className='column council-block no-top no-bottom'>
        <h2>{texts.team.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left ' style={{ width: '50%' }}>
            <h3>{councils[10].council_title}</h3>
            <div className='padding-text' dangerouslySetInnerHTML={{ __html: councils[10].council_text }} />

            {
              social_service[social_service_year]
                ? <React.Fragment>
                  <h3>{social_service[social_service_year].council_title}</h3>
                  <div style={{ position: 'relative', cursor: 'pointer' }}>
                    <div onClick={this.onActiveSelector} className='year-selector'>
                      <p>{social_service_year}</p>
                      <span className='icon-search-05 top' />
                    </div>
                    {
                      isActive
                        ? <div className='year-option-container' style={{ background: appState.colorTheme.base }}>
                          {years.map(year => <p key={year} onClick={() => this.setYear(year)}>{year}</p>)}
                        </div>
                        : null
                    }
                  </div>
                  <div className='padding-text' dangerouslySetInnerHTML={{ __html: social_service[social_service_year].council_text }} />
                </React.Fragment>
                : null
            }
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), width: '50%' }}>
            <h3>{councils[5].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[5].council_text }} />
          </div>
        </div>
      </div>

    </div>

  }
}

/*


      <div className='column council-block no-top'>
        <h2>{texts.council.toUpperCase()}</h2>
        <div className='block-container'>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[11].council_title}</h3>
            <div className='padding-text' dangerouslySetInnerHTML={{ __html: councils[11].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[5].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[5].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            <h3>{councils[11].council_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: councils[13].council_text }} />
          </div>
          <div className=' council-container no-left' style={{ maxHeight: (appState.itemSize * 3), }}>
            {
              social_service[social_service_year]
                ? <React.Fragment>
                  <h3>{social_service[social_service_year].council_title}</h3>
                  <div style={{ position: 'relative', cursor: 'pointer' }}>
                    <div onClick={this.onActiveSelector} className='year-selector'>
                      <p>{social_service_year}</p>
                      <span className='icon-search-05 top' />
                    </div>
                    {
                      isActive
                        ? <div className='year-option-container' style={{ background: appState.colorTheme.base }}>
                          {years.map(year => <p key={year} onClick={() => this.setYear(year)}>{year}</p>)}
                        </div>
                        : null
                    }
                  </div>
                  <div className='padding-text' dangerouslySetInnerHTML={{ __html: social_service[social_service_year].council_text }} />
                </React.Fragment>
                : null
            }
          </div>
        </div>
      </div>

*/
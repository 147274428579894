import React from 'react'

export default class Selector extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.onClick();
    }
  }

  onClick = (item) => {
    this.props.onClick();
    window.open(item.filelink, "_blank");
  }

  render() {
    return <div ref={this.wrapperRef} className='reports-container' >
      {
        this.props.items.map((item, index) => {
          return <p
            key={index}
            onClick={(e) => { e.stopPropagation(); this.onClick(item); }} className='report-item'
          >{item.year}</p>
        })
      }
    </div>
  }
}
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUrlByType } from '../../../utils/routesDefinition'
import getTexts from '../../../services/langTexts'

/* const getParagraph = paragraph => {
  if (typeof paragraph !== 'string') return ''
  let p = paragraph
  p = p.replace(/<(.|\n)*?>/g, '')
  p = p.split('.')[0]
  p = p.length > 70 ? `${p.substring(0, 67)}...` : p
  return `<h2>${p}</h2>`
} */

class InfoBox extends React.Component {

  state = {
    lang: 'es',
  }

  onChangeLang = () => this.setState(prevState => ({ lang: prevState.lang === 'es' ? 'en' : 'es' }))

  componentDidMount() {
    this.setState({ lang: this.props.appState.lang })
  }

  validateHeight = () => {
    let container = document.getElementById('object-info')
      , gridDiv = document.getElementById('grid-container')
      , contentContainer = document.getElementById('object-container')

    if (container && this.container && gridDiv && contentContainer) {
      container.style.height = `${this.container.offsetHeight}px`
      gridDiv.style.height = `${contentContainer.offsetHeight + this.container.offsetHeight}px`
    }
    return null
  }

  render() {
    let isFav = this.props.resources.type === 'person'
      ? this.props.userAuth.person[this.props.resources.id]
      : this.props.userAuth.objects[this.props.resources.id]
      , texts = getTexts('object-screen', this.props.appState.lang)
      , textContent = this.props.resources.mainContent

    if (typeof this.props.resources.mainContentEn === 'string') {
      textContent = this.state.lang === 'es' ? this.props.resources.mainContent : this.props.resources.mainContentEn
    }

    if (this.props.appState.item === 2) return <div id='infobox-container' className='infobox-container'>
      <div className='content-container'>
        <div ref={ref => this.container = ref}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%', justifyContent: 'space-between' }}>
            {
              typeof this.props.resources.mainContentEn === 'string'
                ? <h3 onClick={this.onChangeLang}>{this.state.lang === 'es' ? 'English ' : 'Español'}</h3>
                : null
            }
            <span onClick={this.props.onClick} className={`mobile-fav  ${isFav ? 'icon-heart2' : 'icon-04'}`} />
          </div>
          {/* <div dangerouslySetInnerHTML={{ __html: extract }} /> */}
          <h3>{this.props.resources.tag_persons[0]}</h3>
          <div dangerouslySetInnerHTML={{ __html: textContent ? textContent : '' }} />
        </div>

        <div className='content-headers'>
          {
            this.props.resources.type === 'object'
              ? <React.Fragment>
                <h3>{this.props.resources.contentOne}</h3>
                <h3>{this.props.resources.contentTwo}</h3>
              </React.Fragment>
              : <div className='person-tags'>
                {this.props.resources.person_tags.map(item => <div>
                  <h3>{typeof item.tag === 'string' ? item.tag.toUpperCase() : item.tag}</h3>
                  <p>{item.year ? item.year : '-'}</p>
                </div>)}
                {this.props.resources.programs.map(item => <div>
                  <h3>{typeof item.program === 'string' ? item.program.toUpperCase() : item.program}</h3>
                  <p>{item.year ? item.year : '-'}</p>
                </div>)}
              </div>
          }
        </div>

        <div>
          <h3>{this.props.resources.contentOne}</h3>
          <h3>{this.props.resources.contentTwo}</h3>

          <div className='tags'>
            {
              this.props.resources.arrayContent.length
                ? <h3>{typeof this.props.resources.type === 'string' && texts[this.props.resources.type]}</h3>
                : null
            }
            {
              typeof this.props.resources.type === 'string' &&
                this.props.resources.type === 'object'
                ? this.props.resources.arrayContent.map((content, index) => <p><Link to={`${getUrlByType(content.objectType)}?id=${content.id}`} key={index}>#{content.collaborator}</Link></p>)
                : this.props.resources.arrayContent.map((content, index) => <p><Link to={`${getUrlByType('object')}?id=${content.id}`} key={index}>#{content.title}</Link></p>)
            }
          </div>
        </div>
      </div>
    </div>

    return <div id='infobox-container' className='infobox-container'>
      <div className='content-container'>
        <div>
          <h3>{this.props.resources.tag_persons[0]}</h3>
          {/* <div dangerouslySetInnerHTML={{ __html: extract }} /> */}
          <div ref={ref => this.container = ref} className='content-data' dangerouslySetInnerHTML={{ __html: textContent ? textContent : '' }} />
        </div>

        <div className='content-headers'>
          {
            this.props.resources.type === 'object'
              ? <React.Fragment>
                <h3>{this.props.resources.contentOne}</h3>
                <h3>{this.props.resources.contentTwo}</h3>
              </React.Fragment>
              : <div className='person-tags'>
                {this.props.resources.person_tags.map(item => <div>
                  <h3>{typeof item.tag === 'string' ? item.tag.toUpperCase() : item.tag}</h3>
                  <p>{item.year ? item.year : '-'}</p>
                </div>)}
                {this.props.resources.programs.map(item => <div>
                  <h3>{typeof item.program === 'string' ? item.program.toUpperCase() : item.program}</h3>
                  <p>{item.year ? item.year : '-'}</p>
                </div>)}
              </div>
          }
        </div>
      </div>
      <div className='action-buttons'>
        <div className='button-container'>
          {
            typeof this.props.resources.mainContentEn === 'string'
              ? <h3 onClick={this.onChangeLang}>{this.state.lang === 'es' ? 'English ' : 'Español'}</h3>
              : null
          }
          <span onClick={this.props.onClick} className={isFav ? 'icon-heart2' : 'icon-04'} />
          <span onClick={() => this.props.history.goBack()} className="icon-search-04" />
        </div>

        <div className={`tags clickeable`}>
          {
            this.props.resources.arrayContent.length
              ? <h3>{typeof this.props.resources.type === 'string' && texts[this.props.resources.type]}</h3>
              : null
          }
          {
            typeof this.props.resources.type === 'string' &&
              this.props.resources.type === 'object'
              ? this.props.resources.arrayContent.map((content, index) => <p><Link to={`${getUrlByType(content.objectType)}?id=${content.id}`} key={index}>#{content.collaborator}</Link></p>)
              : this.props.resources.arrayContent.map((content, index) => <p><Link to={`${getUrlByType('object')}?id=${content.id}`} key={index}>#{content.title}</Link></p>)
          }
        </div>
      </div>
    </div >
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  userAuth: state.userAuth
})
export default connect(mapStateToProps)(InfoBox)
import React from 'react'
import { connect } from 'react-redux'

class Grid extends React.Component {

  render() {
    if (!this.props.children.length || this.props.children.props) return null

    let { appState } = this.props
      , size = appState.itemSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , heightExtra = document.getElementsByTagName('BODY')[0].offsetHeight
      , gridSize = this.props.children[this.props.children.length - 1]
      , addExtra = this.props.children[this.props.children.length - 1].props.values.withExtra
      , paddingTop = this.props.paddingTop ? this.props.paddingTop : 0

    // if (noScroll) padding += this.getScrollbarWidth()

    padding = Math.round((padding - (size * appState.item)) / 2)

    let lastItemSize = gridSize ? (gridSize.props.values.h - 1) : 0
      , fullMode = false

    lastItemSize = lastItemSize > 0 ? (lastItemSize * size) : 0
    gridSize = gridSize ? gridSize.props.values.y + 1 : 0
    gridSize = gridSize * size
    gridSize = gridSize + lastItemSize
    heightExtra = heightExtra - (this.props.appState.headerItemSize + (appState.itemSize * 2))
    heightExtra = heightExtra < 130 ? 130 : heightExtra
    heightExtra = heightExtra > 150 ? 150 : heightExtra
    gridSize = addExtra ? gridSize + heightExtra : gridSize


    if (this.props.children[0].props.values.fullMode) {
      gridSize = document.getElementsByTagName('BODY')[0].offsetHeight - appState.headerItemSize
      fullMode = true
    }
    return <div id='grid-container' style={{ paddingTop: paddingTop, position: 'relative', height: gridSize + paddingTop, background: this.props.shouldHide ? 'transparent' : 'black', paddingLeft: `${padding + 4}px` }}>
      {
        this.props.children.map((item, index) => {
          let { values } = item.props
            , extra = values.withExtra ? heightExtra : 0

          return React.cloneElement(item, {
            id: item.props.values.id ? item.props.values.id : `element-${index}`,
            className: `grid-item ${item.props.className}`,
            key: index,
            style: {
              background: this.props.shouldHide ? 'transparent' : appState.colorTheme.base,
              transitionProperty: 'left, top',
              height: fullMode ? `${gridSize}px` : `${(values.h * size) - 2 + extra}px`,
              transform: `translate(${(size * values.x)}px, ${(size * values.y)}px)`,
              width: `${(values.w * size) - 2}px`,
              overflow: item.props.values.allowOverflow ? 'auto' : 'hidden'
            }
          })
        })
      }
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Grid)

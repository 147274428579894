import React from 'react'
import ImageGrid from './ImageGrid'
import InfoDesc from './InfoDesc'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import store from '../../../redux/store'
import { applyFilters, setInitialized, resetAppliedFilters } from '../../../redux/actions/objects'
import { filtersBase } from '../../../utils/filters'

const onClick = (filters, history) => {
  store.dispatch(resetAppliedFilters())
  store.dispatch(applyFilters({ ...filtersBase, ...filters }))
  store.dispatch(setInitialized(true))
  history.push(getUrl('objects'))
}

const langText = {
  'es': {
    btnTitle: 'Ver Todos',
    btnSubtitle: 'actuales'
  },
  'en': {
    btnTitle: 'See all',
    btnSubtitle: 'participants'
  }
}

const showRoom = props => {

  let { appState, expositions } = props
    , columnSize = appState.itemSize
    , items = expositions.items
      .map(item => ({ ...item, rand: Math.random() }))
      .sort((a, b) => a.value - b.value)
    , year = (new Date()).getFullYear()
    , lang = langText[appState.lang]

  if (appState.item === 6) items = items.filter((_, i) => i < 5)
  if (appState.item === 4) items = items.filter((_, i) => i < 3)
  if (appState.item === 2) items = items.filter((_, i) => i < 3)


  if (appState.item === 2) return <div id='proyecto-final'>
    <div className='column no-top' style={{ height: (columnSize * 4) }}>
      <div className='row no-top' style={{ minHeight: (columnSize * 2) }}>
        <InfoDesc  {...props} fullDescription={{ onClick: () => { onClick(expositions.filters, props.history) }, title: expositions.title, paragraph: expositions.description, filters: expositions.filters }} />
      </div>
      <div className='row no-top' style={{ minHeight: (columnSize * 2) }}>
        <ImageGrid {...props} position='left' banner={expositions.banner} />
      </div>
    </div>
    <div className='row no-top' style={{ flexWrap: 'wrap' }}>
      {
        items
          .map((item, index) => <Link to={`${getUrl('objectsObject')}?id=${item.id}`} className={`item item-button column goal-border ${index % 2 === 0 ? 'no-left' : 'no-right'}`} key={index}
            style={{ maxWidth: '50%', width: columnSize, height: columnSize, }}
          >
            <div>
              <h2>{item.title}</h2>
              <h4>{item.person}</h4>
            </div>
            <div className='item-action-button'>
              <p className='font-button'>{item.date}</p>
              <span className="icon-search-05"></span>
            </div>
          </Link>)
      }
      <div className='item item-button goal-border no-left' onClick={() => props.onShowList(expositions.items, expositions.title)} style={{ maxWidth: '50%', background: appState.colorTheme.secondary, width: columnSize, borderBottom: '2px solid black', borderLeft: '2px solid black' }} >
        <div>
          <h2>{lang.btnTitle}</h2>
          <h4>{year}</h4>
        </div>
        <div className='item-action-button item-button-width'>
          <p> </p>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>

  return <div id='proyecto-final'>
    <div className='row no-top' style={{ height: (columnSize * 2) }}>
      <ImageGrid {...props} position='right' banner={expositions.banner} />
      <div style={{ height: columnSize * 2, width: 4, background: 'black' }} />
      <InfoDesc {...props} fullDescription={{ onClick: () => { onClick(expositions.filters, props.history) }, title: expositions.title, paragraph: expositions.description, filters: expositions.filters }} />
    </div>
    <div className='row' style={{ height: (columnSize) }}>
      <div className='row-items' style={{ height: (columnSize) }}>
        {
          items.map((item, index) => <Link to={`${getUrl('objectsObject')}?id=${item.id}`}
            data-title={item.title}
            className={`item item-button item-button-width column  ${index === 0 ? 'no-left' : ''}`} key={index}
            style={{ height: columnSize, }}>
            <div>
              <h2>{item.title}</h2>
              <h4>{item.person}</h4>
            </div>
            <div className='item-action-button'>
              <p className='font-button'>{item.date}</p>
              <span className="icon-search-05"></span>
            </div>
          </Link>)
        }
      </div>
      <div className='item item-button item-button-width-end item-button-end' data-title={lang.btnTitle} onClick={() => props.onShowList(expositions.items, expositions.title)} style={{ background: appState.colorTheme.secondary, borderBottom: '2px solid black', borderLeft: '2px solid black' }} >
        <div>
          <h2>{lang.btnTitle}</h2>
          <h4>{year}</h4>
        </div>
        <div className='item-action-button'>
          <p> </p>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>
}

export default showRoom
import React from 'react'
import { connect } from 'react-redux'
import { setPage } from '../../../redux/actions/objects'

class Paginator extends React.Component {

  onSetPage = page => {
    this.props.dispatch(setPage(page))
    this.props.onSearch()
  }

  render() {
    let { numPages, page } = this.props.objects
    let lastPages = []
      , nextPages = []
      , firstLimit = false
      , lastLimit = false


    for (let index = 1; index <= 4; index++) {
      lastPages.push(page - index)
      nextPages.push(page + index)
    }

    lastPages = lastPages.filter(element => element >= 0)
    nextPages = nextPages.filter(element => element < numPages)


    if (lastPages.length === 4 && nextPages.length === 4) {
      lastPages = lastPages.filter((_, i) => i < 2)
      nextPages = nextPages.filter((_, i) => i < 2)
    } else if (lastPages.length < 4) {
      if (numPages !== 3 && numPages !== 4) {
        let limit = nextPages.length - lastPages.length
        nextPages = nextPages.filter((_, i) => i < limit)
      }
    } else if (nextPages.length < 4) {
      let limit = lastPages.length - nextPages.length
      lastPages = lastPages.filter((_, i) => i < limit)
    }

    /* 

    if (nextPages.length < 5 && lastPages.length > 0) lastPages = lastPages.filter((_, i) => i < lastPages.length - nextPages.length)

    if (lastPages.length === 5 && nextPages.length === 5) {
      lastPages = lastPages.filter((_, i) => i < 3)
      nextPages = nextPages.filter((_, i) => i < 3)
    }

    //lastPages = lastPages.reverse().filter(element => element >= 0)
     */

    lastPages = lastPages.reverse()
    firstLimit = !!lastPages.length && lastPages[0] !== 0
    lastLimit = !!nextPages.length && nextPages[nextPages.length - 1] !== (numPages - 1)

    console.log(nextPages)

    return <div className='paginator'>
      <div className='paginator-content'>
        <span onClick={() => { if (this.props.objects.page - 1 >= 0) this.onSetPage(this.props.objects.page - 1) }} className="icon-search-05 left" />
        {
          firstLimit
            ? <React.Fragment>
              <p onClick={() => this.onSetPage(0)}>1</p>
              <p>...</p>
            </React.Fragment>
            : null
        }
        {
          lastPages.map(page => <p onClick={() => this.onSetPage(page)} key={page}>{page + 1}</p>)
        }
        <div className='current-page'>
          <h4>{this.props.objects.page + 1}</h4>
        </div>
        {
          nextPages.map(page => <p onClick={() => this.onSetPage(page)} key={page}>{page + 1}</p>)
        }
        {
          lastLimit
            ? <React.Fragment>
              <p>...</p>
              <p onClick={() => this.onSetPage(numPages - 1)}>{numPages}</p>
            </React.Fragment>
            : null
        }
        <span onClick={() => { if (this.props.objects.page + 1 < this.props.objects.numPages) this.onSetPage(this.props.objects.page + 1) }} className="icon-search-05 right" />
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})

export default connect(mapStateToProps)(Paginator)
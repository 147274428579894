import React from 'react'
import { connect } from 'react-redux'
import getTemplate from './templates'
import { searchSuggestions, searchObjects, getBanners } from '../services/pageResources'
import Image from './Image'
import Shape from './Shape'
import SearchResult from './SearchResult'
import Grid from './Grid'
class SearchLayout extends React.Component {

  state = {
    settings: null,
    isFetching: true,
    hasError: null,
    layoutBreak: false,
    items: [],
    isSearchActive: false,
    suggestions: [],
    suggestionsBase: [],
    hasSuggestions: false,
    searchText: '',
    searchResult: [],
    showResult: false,
    resultTemplate: [],
    banners: [],
    isScrollActive: false,
    scrollPos: 0,
    isSearching: false,
  }

  getComponent = (component, props) => {
    let { searchResult, banners } = this.state
    if (component === null) return component
    switch (component) {
      case 'Image': {
        return <Image {...props} cover={banners[props.value]} />
      }
      case 'Shape': return <Shape {...props} />
      case 'SearchResult': return <SearchResult {...props} resultItems={searchResult} />
      default: return null
    }
  }

  onSetSuggestion = searchText => { this.setState({ searchText, suggestions: [] }, this.onSearch) }

  onActiveSearch = () => {
    this.setState({ isSearchActive: true }, () => {
      if (this.searchInput) setTimeout(() => {
        this.searchInput.focus()
      }, 500)
    })
  }

  onSearch = event => {
    this.setState({ suggestions: [] })
    if (event) event.preventDefault()

    getBanners()
      .then(res => {
        this.setState({ banners: res.data })
      })
      .then(() => searchSuggestions(this.state.searchText))
      .then(res => {
        this.setState({ showResult: true }, () => {
          let template = getTemplate('result')[this.props.appState.item]
            .map(item => {
              let value = 0
              if (item.component === 'Shape') value = item.value
              if (item.component === 'Image') value = item.value
              return { ...item, value }
            })
          this.setState({ searchResult: res.results, resultTemplate: template })
        })
      })
      .catch(err => console.log('onSearch err', err))
  }

  onHideSection = () => {
    this.setState({ showResult: false, isSearchActive: false, searchText: '' }, () => {
      setTimeout(() => {
        this.setState({ searchResult: [], suggestions: [], })
      }, 900)
    })
  }

  onSearchChange = event => {
    let { searchText, suggestionsBase } = this.state

    this.setState({ searchText: event.target.value }, () => {
      if (this.state.searchText.length >= 2) {
        searchSuggestions(this.state.searchText)
          .then(res => {
            if (res.textFilter.length === this.state.searchText.length) {
              this.setState({ suggestions: res.results, suggestionsBase: res.results, isSearching: false })
            }
            if (this.state.searchText.length === 0) this.setState({ suggestions: [], })
          })
          .catch(err => console.log(err))
      }
    })
  }

  onScroll = e => {

    if (this.props.home) return

    let { isScrollActive } = this.state

    this.setState({ scrollPos: document.getElementById('search-content').scrollTop })
    if (document.getElementById('search-content').scrollTop > 10 && !isScrollActive) {
      this.setState({ isScrollActive: true })
      document.getElementById('search-content').scrollTo(1000, 100)
    } else if (isScrollActive && document.getElementById('search-content').scrollTop < 1) {
      this.setState({ isScrollActive: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appState.itemSize !== this.props.appState.itemSize && this.state.showResult) {
      let template = getTemplate('result')[this.props.appState.item]
        .map(item => {
          let value = 0
          if (item.component === 'Shape') value = item.value
          if (item.component === 'Image') value = item.value
          return { ...item, value }
        })
      this.setState({ resultTemplate: template })
    }
  }

  render() {
    const { isSearchActive, suggestions, searchText, isScrollActive } = this.state
    let header = React.cloneElement(this.props.header, { onActiveSearch: this.onActiveSearch, ...this.props.header.props, isScrollActive })
    let searchBarStyles = { background: this.props.appState.colorTheme.base, height: (this.props.appState.itemSize / 3), width: '100%', opacity: 1 }
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
    let suggestionStyles = {
      borderLeft: '2px solid black',
      borderRight: '2px solid black',
      borderBottom: '2px solid black',
      borderTop: '2px solid black',
      marginLeft: '-1px',
      width: this.props.appState.item === 6 ? (this.props.appState.itemSize * 4) + 3 : this.props.appState.itemSize * this.props.appState.item + 5
    }
    if (this.props.appState.item === 6) {
      searchBarStyles.width = (this.props.appState.itemSize * 4) + 4
      searchBarStyles.marginLeft = this.props.appState.itemSize - 2
    }
    padding = Math.round((padding - (this.props.appState.itemSize * this.props.appState.item)) / 2)

    let styles = { height: isSearchActive ? (this.props.appState.itemSize / 3) : 0, borderBottom: `${isSearchActive ? 2 : 0}px solid black` }
    styles = isSearchActive ? { ...styles, borderLeft: `${padding + 4}px solid black`, borderRight: '4px solid black' } : styles

    styles.height = suggestions.length && isSearchActive ? styles.height + (this.props.appState.itemSize * 2) + 2 : styles.height
    styles.backgroundColor = this.props.appState.colorTheme.base

    let marginTop = isScrollActive
      ? this.props.appState.headerItemSize / 2 + styles.height
      : this.props.appState.headerItemSize + styles.height

    if (this.props.home) marginTop = 0
    let headerClasses = ''
    if (!this.props.home) headerClasses = `search-header ${isScrollActive ? 'fixed' : ''}`

    styles.borderLeft = '3px solid black'
    styles.borderRight = '3px solid black'

    return <div className='search-main-container' onScroll={this.onScroll}>
      <div id='search-content' className='search-content' >
        <div className={headerClasses}>
          {header}
          <div className='search-bar-wrap' style={styles} >
            <div className='search-bar-container'>
              <div className='search-bar' style={searchBarStyles}>
                <form onSubmit={this.onSearch} className='search-container' >
                  <input ref={ref => this.searchInput = ref} value={searchText} onChange={this.onSearchChange} type='text' className='search-input' placeholder=' Busca en SOMA' />
                  <div className='close-btn' onClick={this.onHideSection}>
                    <span className="icon-search-04"></span>
                  </div>
                </form>
                {
                  suggestions.length
                    ? <div className='search-suggestions'
                      style={{ ...suggestionStyles, minHeight: `${(this.props.appState.itemSize * 2) + 2}px`, maxHeight: `${(this.props.appState.itemSize * 2) + 2}px`, background: this.props.appState.colorTheme.base }}>
                      {
                        suggestions.map((suggestion, index) => <div key={index} className='suggestion-item' onClick={() => this.onSetSuggestion(suggestion.title)}>{suggestion.title}</div>)
                      }
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className='search-animation-container' style={{ marginTop }}>
          {
            !this.state.showResult
              ? this.props.children
              : null
          }
          {
            this.state.showResult
              ? <Grid>
                {
                  this.state.resultTemplate
                    .map((item, index) => {
                      return <div
                        style={{ background: this.props.appState.colorTheme.base, }}
                        className={item.className ? item.className : 'item'}
                        key={index + 7} values={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true }}>
                        {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
                      </div>
                    })
                }
              </Grid>
              : null
          }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})
export default connect(mapStateToProps)(SearchLayout)


/*




*/
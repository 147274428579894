import React from 'react'
import { connect } from 'react-redux'

class HeaderGrid extends React.Component {



  render() {
    let { appState } = this.props
      , size = appState.headerItemSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , height = size
      , transition = {}

    if (this.props.middle && this.props.appState.item > 2) height = height / 2

    if (this.props.isScrollActive && appState.item > 2) {
      height = height / 2
    }

    padding = Math.round((padding - (size * appState.headerItem)) / 2)
    return <div className='header-animation' style={{ ...transition, position: 'relative', height: height + 4, background: this.props.shouldHide ? 'transparent' : 'black', paddingTop: '4px', paddingLeft: `${4 + padding}px` }}>
      {
        this.props.children.map((item, index) => {
          let { values } = item.props
          return React.cloneElement(item, {
            className: `grid-item ${item.props.className}`,
            style: {
              background: this.props.shouldHide ? 'transparent' : appState.colorTheme.base,
              height: `${(values.h * height) - 2}px`,
              transform: `translate(${size * values.x}px, ${size * values.y}px)`,
              width: `${(values.w * size) - 2}px`,
              overflow: 'hidden',
            }
          })
        })
      }
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(HeaderGrid)

import React from 'react'
import Loading from '../../baseComponents/Loading'
import Header from './components/Header'
import Slider from './components/Slider'
import Description from './components/Description'
import FullDescription from './components/FullDescription'
import Logo from './components/Logo'
import History from './components/History'
import Councils from './components/Councils'
import Map from './components/Map'
import SearchLayout from '../../baseComponents/SearchLayout'
import Newsletter from '../../baseComponents/Newsletter'
import ContactProgram from '../../baseComponents/ContactProgram'
import Grid from '../../baseComponents/Grid'
import { connect } from 'react-redux'
import getTemplate from './template'
import { getAboutResources } from '../../services/pageResources'

import './AboutScreen.css'

class AboutScreen extends React.Component {

  state = {
    resources: null,
    isFetching: true,
    isFetchingEnd: false,
    error: null,
  }

  getComponent = (component, props) => {
    let { resources } = this.state
    if (component === null) return component
    switch (component) {
      case 'Slider': return <Slider {...props} slides={resources.slides} />
      case 'Description': return <Description shortDescription={resources.shortDescription} fullDescription={resources.sections.fullDescription} {...props} />
      case 'FullDescription': return <FullDescription fullDescription={resources.sections.fullDescription} {...props} />
      case 'Logo': return <Logo fullDescription={resources.sections.logo} {...props} />
      case 'Map': return <Map {...props} />
      case 'History': {
        let { history } = resources
        return <History banner={resources.mainBanner} years={history} {...props} />
      }
      case 'Councils': {
        let reports = []
        if (resources.reports && resources.reports.items) {
          reports = resources.reports.items
        }
        return <Councils fullDescription={resources.sections.fullDescription} councils={resources.councils} {...props} reports={reports} />
      }
      case 'Newsletter': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <Newsletter color={color} />
      }
      case 'ContactProgram': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <ContactProgram color={color} />
      }
      default: return null
    }
  }

  getData = () => {
    getAboutResources()
      .then(res => {
        this.setState({ isFetchingEnd: true, resources: res.data }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      })
      .catch(err => console.log('error', err))
    this.setState({ template: getTemplate()[this.props.appState.item] })
  }

  renderButton = () => {
    let { lang } = this.props.appState
    if (lang === 'en') {
      const script = document.createElement('script')
      script.id = 'button-en'
      script.src = (function (w, d, s, n, a) {
        if (!w[n]) {
          var l = 'call,catch,on,once,set,then,track'
            .split(','), i, o = function (n) {
              return 'function' == typeof n ? o.l.push([arguments]) && o
                : function () { return o.l.push([n, arguments]) && o }
            }, t = d.getElementsByTagName(s)[0],
            j = d.createElement(s); j.async = !0; j.src = 'https://cdn.fundraiseup.com/widget/' + a;
          t.parentNode.insertBefore(j, t); o.s = Date.now(); o.v = 4; o.h = w.location.href; o.l = [];
          for (i = 0; i < 7; i++)o[l[i]] = o(l[i]); w[n] = o
        }
      })(window, document, 'script', 'FundraiseUp', 'ADQMPSVG');
      this.showFunButton()
    } else {
      this.removeFunButton()
    }
  }

  showFunButton = () => {
    let buttonEn = document.getElementsByClassName('fun-widget')
    buttonEn = Array.from(buttonEn)
    for (let index = 0; index < buttonEn.length; index++) {
      let iframe = buttonEn[index]
      iframe.style.display = 'block'
    }
  }

  removeFunButton = () => {
    let buttonEn = document.getElementsByClassName('fun-widget')
    buttonEn = Array.from(buttonEn)
    for (let index = 0; index < buttonEn.length; index++) {
      let iframe = buttonEn[index]
      iframe.style.display = 'none'
    }
  }

  componentWillUnmount() {
    this.removeFunButton()
  }


  componentDidMount() {
    this.getData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        resources: null,
        isFetching: true,
        isFetchingEnd: false,
        error: null,
      }, this.getData)
    }
  }

  render() {
    let { isFetching, isFetchingEnd } = this.state
      , { appState } = this.props

    if (isFetching) return <Loading isOut={isFetchingEnd} />

    return <div id='about'>
      <SearchLayout header={<Header />}>
        {this.renderButton()}
        <div id="main" style={{ marginTop: `${0}px` }} >
          <Grid>
            {
              getTemplate()[appState.item].map((item, index) => <div
                key={index}
                values={{ x: item.x, y: item.y, w: item.w, h: item.h, }}
              >
                {this.getComponent(item.component, { ...this.props, ...item })}
              </div>)
            }
          </Grid>
        </div>
      </SearchLayout>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})
export default connect(mapStateToProps)(AboutScreen)

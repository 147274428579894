import React from 'react'
import { applyFilters, setInitialized } from '../../../redux/actions/objects'
import { filtersBase } from '../../../utils/filters'
import { getUrl } from '../../../utils/routesDefinition'

export default class Content extends React.Component {

  state = {
    activeTestimonial: { ...this.props.testimonials.items[0], id: 0, },
    activeWeek: { ...this.props.program.weeks[0], id: 0, },
    activeDropdown: false,
  }

  getBanner = (banner = 0, top, bottom) => {
    let { appState, banners } = this.props
    if (appState.item === 4) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row' />
      <div className='item-row' />
      <div className='item-row no-right' />
    </div>

    if (appState.item === 2) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row no-right' />
    </div>

    return null
  }

  setTestimonial = value => {
    let { activeTestimonial } = this.state
      , { testimonials } = this.props
      , index = activeTestimonial.id + value < 0 ? testimonials.items.length - 1 : activeTestimonial.id + value
    index = testimonials.items.length - 1 < index ? 0 : index

    activeTestimonial = testimonials.items.find((_, i) => i === index)
    this.setState({ activeTestimonial: { ...activeTestimonial, id: index, } })
  }

  setWeek = value => {
    let { program } = this.props
    program = program.weeks.find((_, index) => index === value)
    this.setState({ activeWeek: { id: value, ...program }, activeDropdown: false })
  }


  setFilters = () => {
    let years = this.props.sections.about.filters.year ? this.props.sections.about.filters.year : this.props.sections.about.filters.years
    years = years.map(year => Number(year))
    let filters = { ...filtersBase, ...this.props.sections.about.filters, year: years }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  componentDidMount() {
    if (this.props.location.hash === '#info') document.getElementById('requirements-summer').scrollIntoView()
    if (this.props.location.hash === '#program') document.getElementById('weeks-summer').scrollIntoView()
  }

  render() {
    let { appState, sections, partnerships, testimonials, lastItem } = this.props
      , { activeTestimonial } = this.state
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , size = appState.itemSize
      , left = size + 4
      , width = (size * 4) - 1
      , imgHeight = appState.item === 2 ? size : size / 2

    padding = Math.round((padding - (size * appState.item)) / 2)

    left += padding

    if (appState.item !== 6) left = 4
    if (appState.item === 2) {
      width = size * 2
      imgHeight = imgHeight / 2
    }

    return <div className='content-container' style={{ height: (lastItem.y) * size, left, width, background: appState.colorTheme.base }}>
      {this.getBanner(0, true, false)}
      <div id='about-summer' className='content-padding'>
        <h2>{sections.about.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: sections.about.description }} />
        {
          appState.item !== 6
            ? <div onClick={this.setFilters} className="more-button" style={{ color: appState.colorTheme.secondary }}>
              <div>See previous years</div>
              <span className="icon-search-05"></span>
            </div>
            : null
        }
      </div>

      {
        /*
        <div id='weeks-summer' className='content-padding'>
        <div className='tutor-action'>
          <h2>{program.title}</h2>
          <div className='dropdown-button'>
            <div onClick={() => { this.setState(prevState => ({ activeDropdown: !prevState.activeDropdown })) }}>
              <p style={{ color: this.props.appState.colorTheme.secondary }}> week {activeWeek.week}</p>
              <span className={`icon-search-05 icon-dropdown ${activeDropdown ? 'icon-active' : 'icon-inactive'}`}></span>
            </div>
            {
              activeDropdown
                ? <div className='dropdown-container' style={{ background: this.props.appState.colorTheme.base }}>
                  {
                    program.weeks.map((week, index) => index === activeWeek.id
                      ? null
                      : <p className='item-dropdown' onClick={() => this.setWeek(index)}>week {week.week}</p>)
                  }
                </div>
                : null
            }
          </div>
        </div>
        <div className='testimonials-container'>
          <h3>week {activeWeek.week} - {activeWeek.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: activeWeek.description }} />
        </div>
      </div>

        */
      }
      <div id='requirements-summer' className='content-padding'>
        <h2>{sections.requirements.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: sections.requirements.description }} />
      </div>
      {this.getBanner(1)}
      <div id='housing-summer' className='content-padding'>
        <h2>{sections.housing.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: sections.housing.description }} />
      </div>
      <div id='financialAID-summer' className='content-padding'>
        <h2>{sections.financialAID.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: sections.financialAID.description }} />
      </div>
      {this.getBanner(2)}

      <div id='testimonials-summer' className='content-padding'>
        <div className='tutor-action'>
          <h2>{testimonials.title}</h2>
          <p>
            <span className="icon-search-05 arrow-btn" onClick={() => this.setTestimonial(-1)}></span>
            {activeTestimonial.id + 1}/{testimonials.items.length}
            <span className="icon-search-05 arrow-btn" onClick={() => this.setTestimonial(1)}></span>
          </p>
        </div>
        <div className='testimonials-container'>
          <h3>{activeTestimonial.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: activeTestimonial.testimonial }} />
        </div>
      </div>

      <div id='goals-pes' className='content-padding'>
        <h2 style={{ textTransform: 'uppercase' }}>{partnerships.title}</h2>
        <div>
          {
            partnerships.items
              .filter(item => Number.isInteger(item.order))
              .sort((a, b) => a.order - b.order)
              .map((item, index) => <div key={index} className='objective-item' style={{ height: imgHeight, }}>
                <img alt='banner' src={item.banner} style={{ width: appState.item === 2 ? '50%' : '70%' }} />
              </div>)
          }
        </div>
      </div>


    </div>
  }
}

/*


        <div className='active-testimonials row no-top no-bottom'>
          <div className='content-column'>
            <img src={activeTutor.banner} style={{ width: '100%', height: '25vw' }} />
            <div className='tutor-action'>
              <h2>{activeTutor.name}</h2>
              <p>
                <span className="icon-search-05 arrow-btn" onClick={() => this.setTutor(-1)}></span>
                {activeTutor.id + 1}/{main_tutors.items.length}
                <span className="icon-search-05 arrow-btn" onClick={() => this.setTutor(1)}></span>
              </p>
            </div>
          </div>
          <div className='content-column'>
            <div dangerouslySetInnerHTML={{ __html: activeTutor.content }} />
          </div>
        </div>


*/
import React from 'react'
import { connect } from 'react-redux'
import { getCountryList } from '../../../utils/countries'
import { isFieldValid } from '../../../utils/filedValidation'
import { createUser, authUserService } from '../../../services/auth'
import { authUser } from '../../../redux/actions/userAuth'
import { getUrl } from '../../../utils/routesDefinition'
import getTexts from '../../../services/langTexts'

const getGenders = lang => {
  return lang === 'es'
    ? ['Hombre', 'Mujer', 'Otro', 'No especificar']
    : ['Hombre', 'Mujer', 'Otro', 'No especificar']
}

class Content extends React.Component {

  state = {
    registerError: '',
    initialized: false,
    avatar: null,
    avatarName: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    birthYear: '',
    country: '',
    state: '',
    occupation: '',
    gender: '',
    suscribe: false,
    newsletter: false,
    isOptionVisible: false,
    isOptionVisible2: false,
    showAlert: false,
    showAlert2: false,
    fieldMessage: '',
    fieldMessage2: '',
    steps: [
      { title: 'nameFieldTitle', type: 'text', target: 'name', placeholder: 'nameFieldPlaceholder', required: true, name: 'username' },
      { title: 'emailFieldTitle', type: 'text', target: 'email', placeholder: 'emailFieldPlaceholder', required: true, name: 'email' },
      { title: 'pwFieldConfirmTitle', type: 'password', target: 'confirmPassword', placeholder: '', tip: 'pwFieldConfirmPlaceholder', required: true, name: 'password' },
      { title: 'countryFieldTitle', type: 'select', target: 'country', placeholder: 'countryFieldPlaceholder' },
      { title: 'jobFieldTitle', type: 'text', target: 'occupation', placeholder: 'jobFieldPlaceholder', },
    ],
    steps2: [
      { title: 'picFieldTitle', type: 'image', target: 'avatar', placeholder: 'picFieldPlaceholder', },
      { title: 'pwFieldTitle', type: 'password', target: 'password', placeholder: '', tip: 'pwFieldPlaceholder', required: true, name: 'password' },
      { title: 'yearFieldTitle', type: 'year', target: 'birthYear', placeholder: 'yearFieldPlaceholder', required: true, name: 'year' },
      { title: 'cityFieldTitle', type: 'text', target: 'state', placeholder: 'cityFieldPlaceholder' },
      { title: 'genderFieldTitle', type: 'gender', target: 'gender', placeholder: 'genderFieldPlaceholder', required: true, name: 'gender' },
    ],
    currentStep: 0
  }

  /*
  
      { title: '¿Deseas recibir noticias de eventos e información actualizada de la comunidad?', type: 'checkbox', target: 'name', placeholder: '' },
  
  */

  imageRef = null

  onClickInput = () => { this.setState(prevState => ({ isOptionVisible: !prevState.isOptionVisible })) }

  onClickInput2 = () => { this.setState(prevState => ({ isOptionVisible2: !prevState.isOptionVisible2 })) }

  onSetOption = country => { this.setState({ country, isOptionVisible: false }) }

  onSetYear = birthYear => { this.setState({ birthYear, isOptionVisible: false }) }

  onSetGender = gender => { this.setState({ gender, isOptionVisible2: false }) }

  getInput = (stepinfo) => {
    let { appState } = this.props
      , texts = getTexts('register-screen', appState.lang)
      , { avatarName } = this.state

    switch (stepinfo.type) {
      case 'text': return <input type='text' placeholder={texts[stepinfo.placeholder]} value={this.state[stepinfo.target]} onChange={e => { this.setState({ [stepinfo.target]: e.target.value }) }} />
      case 'password': return <input type='password' value={this.state[stepinfo.target]} placeholder={texts[stepinfo.placeholder]} onChange={e => { this.setState({ [stepinfo.target]: e.target.value }) }} />
      case 'image': return <React.Fragment>
        <div onClick={() => { this.imageRef.click() }} className='pic-selector'>{avatarName.length ? avatarName : texts[stepinfo.placeholder]}</div>
        <input style={{ display: 'none' }} ref={ref => this.imageRef = ref} type='file' id='avatar' name='avatar' accept='image/png, image/jpeg' onChange={e => {
          const files = Array.from(e.target.files)
          this.setState({ [stepinfo.target]: files[0], avatarName: files[0].name })
        }} />
      </React.Fragment>
      case 'year': {
        let lastYear = (new Date()).getFullYear()
          , years = []
        for (let year = lastYear; year >= lastYear - 100; year--) years.push(`${year}`)
        return <div className='select-input-container' onClick={e => e.stopPropagation()}>
          <div onClick={this.onClickInput} className='select-input'>
            {this.state[stepinfo.target].length ? this.state[stepinfo.target] : texts[stepinfo.placeholder]}
            <span className={`icon-search-05 ${this.state.isOptionVisible ? 'active' : ''}`} />
          </div>
          {
            this.state.isOptionVisible
              ? <div className='select-input-option' style={{ height: this.props.appState.itemSize - 60, background: this.props.appState.colorTheme.base }}>
                {years.map(item => <p onClick={() => this.onSetYear(item)}>{item}</p>)}
                {this.state.showAlert ? <p>{this.state.fieldMessage}</p> : null}
              </div>
              : null
          }
        </div>
      }
      case 'gender': return <div className='select-input-container' onClick={e => e.stopPropagation()}>
        <div onClick={this.onClickInput2} className='select-input'>
          {this.state[stepinfo.target].length ? this.state[stepinfo.target] : texts[stepinfo.placeholder]}
          <span className={`icon-search-05 ${this.state.isOptionVisible2 ? 'active' : ''}`} />
        </div>
        {
          this.state.isOptionVisible2
            ? <div className='select-input-option' style={{ height: this.props.appState.itemSize - 60, background: this.props.appState.colorTheme.base }}>
              {getGenders(this.props.appState.lang).map(item => <p onClick={() => this.onSetGender(item)}>{item}</p>)}
              {this.state.showAlert ? <p>{this.state.fieldMessage}</p> : null}
            </div>
            : null
        }
      </div>
      case 'select': return <div className='select-input-container' onClick={e => e.stopPropagation()}>
        <div onClick={this.onClickInput} className='select-input'>
          {this.state[stepinfo.target].length ? this.state[stepinfo.target] : texts[stepinfo.placeholder]}
          <span className={`icon-search-05 ${this.state.isOptionVisible ? 'active' : ''}`} />
        </div>
        {
          this.state.isOptionVisible
            ? <div className='select-input-option' style={{ height: this.props.appState.itemSize - 60, background: this.props.appState.colorTheme.base }}>
              {getCountryList(this.props.appState.lang).map(item => <p onClick={() => this.onSetOption(item.name)}>{item.name}</p>)}
              {this.state.showAlert ? <p>{this.state.fieldMessage}</p> : null}
            </div>
            : null
        }
      </div>
      case 'checkbox': return <div onClick={e => e.stopPropagation()}>
        <label className='checkbox-button'>
          <input type='checkbox' className='checkbox-button__input' id='choice1-1' name='choice1' />
          <span className='checkbox-button__control'>
            <span />
          </span>
          <span className='checkbox-button__label'>Sí, deseo suscribirme al newsletter</span>
        </label>
      </div>
      default: return null
    }
  }

  onPrevStep = e => {
    e.stopPropagation()
    let { currentStep } = this.state
    if (currentStep - 1 >= 0) this.setState({ currentStep: currentStep - 1, showAlert2: false, showAlert: false })
  }

  onNextStep = e => {
    e.stopPropagation()
    let { currentStep, steps, steps2 } = this.state
      , stepinfo = steps[currentStep]
      , stepinfo2 = steps2[currentStep]

    if (stepinfo.required || stepinfo2.required) {
      let field1 = { field: stepinfo.name, name: stepinfo.name, value: this.state[stepinfo.target], }
        , isValidField1 = isFieldValid(field1)
        , field2 = { field: stepinfo2.name, name: stepinfo2.name, value: this.state[stepinfo2.target], }
        , isValidField2 = isFieldValid(field2)

      if (stepinfo2.target === 'confirmPassword' && this.state.confirmPassword !== this.state.password) isValidField2 = { message: 'La contraseña no coincide' }

      if (isValidField1 || isValidField2) {
        this.setState({
          showAlert: stepinfo.required && isValidField1,
          fieldMessage: stepinfo.required && isValidField1 ? isValidField1.message : '',
          showAlert2: stepinfo2.required && isValidField2,
          fieldMessage2: stepinfo2.required && isValidField2 ? isValidField2.message : ''
        })
        return
      }
    }
    if (currentStep + 1 > steps.length - 1) return
    this.setState({ currentStep: currentStep + 1, fieldMessage: '', showAlert: false, fieldMessage2: '', showAlert2: false })
  }

  onCreateUser = async (e) => {
    e.stopPropagation()
    try {
      let response = await createUser(this.state)
        , message = response.message ? response.message : {}
        , messages = Object.values(message).length ? Object.values(message)[0] : ['']

      messages = messages.join(', ')

      if (Number(response.status) == 200) this.authUserHandler()
      else this.setState({ email: '', password: '', registerError: messages })

    } catch (error) {
      console.log('error', error)
    }
  }

  authUserHandler = () => {
    const { password, email } = this.state
    authUserService({ password, email })
      .then(res => {
        if (res.status == 200) {
          localStorage.setItem('0mZTFbxo1u', btoa(JSON.stringify(res)))
          this.props.dispatch(authUser(res))
          this.props.history.push(getUrl('account'))
        } else {
          this.setState({ email: '', password: '', warningFields: [{ message: 'El usuario o password es incorrecto' }] })
        }
      })
      .catch(error => console.log('error', error))
  }

  onClickHandler = () => {
    if (this.state.isOptionVisible) this.setState({ isOptionVisible: false })
    if (this.state.isOptionVisible2) this.setState({ isOptionVisible2: false })
  }

  render() {
    let { currentStep, steps, steps2, initialized } = this.state
      , stepinfo = steps[currentStep]
      , stepinfo2 = steps2[currentStep]
      , { appState } = this.props
      , texts = getTexts('register-screen', appState.lang)

    if (!initialized) return <div className='container'>
      <div className='text-container'>
        <h2>{texts.instructionsTitle}</h2>
      </div>
      <div className='field-container'>
        <div className='field-container-text'>
          <p>{texts.instructionsText1}</p>
          <p>{texts.instructionsText2}</p>
        </div>
      </div>
      <div className='btn-container'>
        <div className='create-btn' onClick={() => this.setState({ initialized: true })}>
          {texts.instructionsBtn}
        </div>
      </div>
    </div>


    return <div className='container' onClick={this.onClickHandler}>
      <h3>{currentStep + 1}/{steps.length}</h3>
      <div className='container-steps'>
        <div className='container-step'>
          <div className='text-container'>
            <h2 style={{ marginBottom: '1vh' }}>{texts[stepinfo.title]}</h2>
          </div>
          <div className='field-container'>
            {this.getInput(stepinfo)}
            {stepinfo.tip ? <p>{texts[stepinfo.tip]}</p> : null}
            {this.state.showAlert ? <p className='warning-text'>* {this.state.fieldMessage}</p> : null}
            {stepinfo.required ? <p>{texts.requiredFieldText}</p> : <p>{texts.notRequiredFieldText}</p>}
          </div>
        </div>
        <div className='container-step'>
          <div className='text-container'>
            <h2 style={{ marginBottom: '1vh' }}>{texts[stepinfo2.title]}</h2>
          </div>
          <div className='field-container'>
            {this.getInput(stepinfo2)}
            {stepinfo2.tip ? <p>{texts[stepinfo2.tip]}</p> : null}
            {this.state.showAlert2 ? <p className='warning-text'>* {this.state.fieldMessage2}</p> : null}
            {stepinfo2.required ? <p>{texts.requiredFieldText}</p> : <p>{texts.notRequiredFieldText}</p>}
          </div>
        </div>
      </div>
      <p>{this.state.registerError}</p>
      <div className='btn-container'>
        <div className='arrow-btn' onClick={this.onPrevStep}>
          <span className='icon-search-05 left-arrow' />
        </div>
        {
          currentStep === steps.length - 1
            ? <div className='create-btn' style={{ height: '100%' }} onClick={this.onCreateUser}>
              {texts.createAccountBtn}
            </div>
            : <div className='arrow-btn' onClick={this.onNextStep}>
              <span className='icon-search-05 right-arrow' />
            </div>
        }
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Content)
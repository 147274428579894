import React from 'react'
import Paginator from './Paginator'
import { connect } from 'react-redux'
import { applyFilters, resetAppliedFilters, setPage } from '../../../redux/actions/objects'
import { getUrlByType, getUrl } from '../../../utils/routesDefinition'
import backgroundLogo from '../../../assets/img/patron_noimage.png'
import Modal from '../../../baseComponents/Modal'
import { authUser } from '../../../redux/actions/userAuth'
import { favUserHandler } from '../../../services/auth'
import getTexts from '../../../services/langTexts'
import Shape, { getShapeType } from '../../../baseComponents/Shape'

let shapes = []
for (let index = 0; index < 50; index++)
  shapes.push(getShapeType())


class Content extends React.Component {

  state = {
    mode: 'image',
    sort: 'name',
    isSortSelectorActive: false,
    isModalVisible: false,
    fav: null,
    mobileSelector: ''
  }

  onClickHandler = item => {
    localStorage.setItem('lastObject', JSON.stringify({
      type: 'objects',
      section: item.id
    }))
    this.props.history.push(`${getUrlByType(item.objectType)}?id=${item.id}`)
  }

  setMode = mode => this.props.setMode(mode)

  onSelectorActive = () => this.setState(prevState => ({ isSortSelectorActive: !prevState.isSortSelectorActive }))

  setSortMode = sort => this.setState({ isSortSelectorActive: false }, () => { this.props.setSortMode(sort) })

  onDeleteFilter = filter => {
    let { filtersApplied } = this.props.objects
    if (filter.type === 'text') filtersApplied[filter.type] = ''
    else if (filter.type === 'year') filtersApplied[filter.type] = filtersApplied[filter.type].filter(f => f !== filter.tag)
    else filtersApplied[filter.type] = filtersApplied[filter.type].filter(f => f.tag !== filter.tag)
    this.props.dispatch(applyFilters(filtersApplied))
    this.props.dispatch(setPage(0))
    this.props.onSearch()
  }

  onMouseEnter = e => {
    e.target.parentNode.classList.add('color-yellow')
  }

  onMouseOut = e => {
    e.target.parentNode.classList.remove('color-yellow')
  }

  sortItems = items => {
    return this.state.sort === 'name'
      ? items.sort((a, b) => (a.title > b.title) - (a.title < b.title))
      : items.sort((a, b) => (a.subtitle > b.subtitle) - (a.subtitle < b.subtitle))
  }

  onSetFav = async (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    if (!this.props.userAuth.id) this.setState({ isModalVisible: true, fav: item })
    else await favUserHandler(item)
  }

  onGoTo = logIn => {
    let { fav } = this.state
      , { userAuth } = this.props
    this.props.dispatch(authUser({ ...userAuth, hasFavToUpdate: fav }))
    this.props.history.push(getUrl(logIn ? 'account' : 'register'))
  }

  setSelector = value => this.setState(prevState => ({ mobileSelector: value === prevState.mobileSelector ? '' : value }))

  onResetFilters = () => {
    this.props.dispatch(resetAppliedFilters())
    this.props.onSearch()
  }

  renderImage = (size, image, index, item) => {
    const { level1 } = this.props.appState.colorTheme
    let regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/
    if (regex.exec(image) === null) return <div className='container-data-image' style={{ height: size, minWidth: size, }}>
      <Shape {...this.props} value={shapes[index]} />
    </div>

    return <div className='container-data-image' style={{ height: size, minWidth: size, background: `url("${backgroundLogo}")` }}>
      <div className='container-data-image' style={{ height: size, minWidth: size, background: `url("${image}")` }} />
    </div>
  }

  componentDidMount() {
    let lastObject = localStorage.getItem('lastObject')
    if (lastObject) {
      lastObject = JSON.parse(lastObject)
      const element = document.getElementById(`object-${lastObject.section}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        localStorage.removeItem('lastObject')
      }
    }
  }

  render() {
    let { sort, mode, appState, objects, items, userAuth } = this.props
      , { isSortSelectorActive, isModalVisible } = this.state
      , height = (appState.itemSize * 2) / 6
      , objectTag = objects.filtersApplied.tag_object.map(tag => ({ type: 'tag_object', ...tag }))
      , programTag = objects.filtersApplied.tag_program.map(tag => ({ type: 'tag_program', ...tag }))
      , personTag = objects.filtersApplied.tag_person.map(tag => ({ type: 'tag_person', ...tag }))
      , yearTag = objects.filtersApplied.year.map(tag => ({ type: 'year', tag }))
      , applied = [...objectTag, ...programTag, ...personTag, ...yearTag]
      , texts = getTexts('object-screen', appState.lang)


    sort += `-${objects.filtersApplied.sort}`

    // items = this.sortItems(items)

    if (objects.filtersApplied.text.length) applied.push({ type: 'text', tag: objects.filtersApplied.text })
    // if (objects.filtersApplied.year !== 0) applied.push({ type: 'year', tag: objects.filtersApplied.year })

    if (!this.props.items.length) return <div style={{ height: '100%' }}>
      {
        appState.item !== 2
          ? <div className='info-container' style={{ height, }}>
            <div className='applied-filters-box' style={{ width: (appState.itemSize * 4) }}>
              <div className='applied-filters-container'>
                {
                  applied.map((filter, index) => <p onClick={() => this.onDeleteFilter(filter)} key={index} className='delete-applied-filters'>
                    <span className="icon-search-04" style={{ color: appState.colorTheme.secondary }}></span>
                    {filter.tag}
                  </p>)
                }
              </div>
              {
                applied.length
                  ? <p onClick={this.onResetFilters} className='delete-applied-filters all-filter'>
                    <span className="icon-search-04"></span>
                    limpiar todos
                  </p>
                  : null
              }
            </div>
            <div className='view-mode-box'>

            </div>
          </div>
          : <div className='info-container' style={{ height: appState.headerItemSize }}>
            <div className='button-mode-container-mobile' style={{ minWidth: appState.headerItemSize }}>
            </div>
            <div className='applied-filters-box-mobile'>
              {
                applied.map((filter, index) => <p onClick={() => this.onDeleteFilter(filter)} key={index} className='delete-applied-filters'>
                  <span className="icon-search-04" style={{ color: appState.colorTheme.secondary }}></span>
                  {filter.tag}
                </p>)
              }
              {
                applied.length
                  ? <p onClick={this.onResetFilters} className='delete-applied-filters'>
                    <span className="icon-search-04"></span>
                    limpiar todos
                  </p>
                  : null
              }
            </div>
          </div>
      }
      <div className='centered no-results'>
        <h2 style={{ textAlign: 'center', marginBottom: '1vw' }}>:(</h2>
        <h2 style={{ textAlign: 'center' }}>SIN RESULTADOS</h2>
        <p>PRUEBA MODIFICANDO ALGUNA PALABRA</p>
      </div>
    </div>

    let styles = appState.item !== 2 ? { flex: 1, display: 'flex', flexDirection: 'column' } : { flex: 1, display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }
      , containerStyles = appState.item !== 2 ? { minHeight: mode === 'image' ? appState.itemSize : 'auto' } : { minHeight: appState.itemSize, }
    let filterHeight = isSortSelectorActive ? height : 0
    return <div style={{ height: '100%' }}>
      {
        appState.item !== 2
          ? <div className='info-container' style={{ height, }}>
            <div className='applied-filters-box' style={{ width: (appState.itemSize * 4) }}>
              <div className='applied-filters-container'>
                {
                  applied.map((filter, index) => <p onClick={() => this.onDeleteFilter(filter)} key={index} className='delete-applied-filters'>
                    <span className="icon-search-04" style={{ color: appState.colorTheme.secondary }}></span>
                    {filter.tag}
                  </p>)
                }
              </div>
              {
                applied.length
                  ? <p onClick={this.onResetFilters} className='delete-applied-filters all-filter'>
                    <span className="icon-search-04"></span>
                    limpiar todos
                  </p>
                  : null
              }
            </div>
            <div className='view-mode-box' style={{ width: appState.itemSize }}>
              {/*  <div className='view-sort-box-container' onClick={this.onSelectorActive} >
                <div className='view-sort-button-container' style={{ height, width: (appState.itemSize / 3) - 2 }}>
                  <span className="icon-05" />
                  <span className={`icon-search-05 arrow ${isSortSelectorActive ? 'active' : ''}`}
                    style={{ color: isSortSelectorActive ? appState.colorTheme.secondary : 'black' }} />
                </div>

                <div className={`view-sort-dropdown-container ${!isSortSelectorActive ? 'inactive' : ''}`} style={{ right: -((appState.itemSize / 3) - 2) }} onClick={e => e.stopPropagation()}>
                  <div className='row'>
                    <div className='column'>
                      <div onClick={() => this.setSortMode('name-ASC')} style={{ background: sort === 'name-ASC' ? appState.colorTheme.secondary : appState.colorTheme.base, height: filterHeight }} >
                        <span className="icon-az2" />
                      </div>
                      <div onClick={() => this.setSortMode('cal-ASC')} style={{ background: sort === 'cal-ASC' ? appState.colorTheme.secondary : appState.colorTheme.base, height: filterHeight }} >
                        <span className="icon-cal2" />
                      </div>
                    </div>
                    <div className='column'>
                      <div onClick={() => this.setSortMode('name-DESC')} style={{ background: sort === 'name-DESC' ? appState.colorTheme.secondary : appState.colorTheme.base, height: filterHeight }} >
                        <span className="icon-az1" />
                      </div>
                      <div onClick={() => this.setSortMode('cal-DESC')} style={{ background: sort === 'cal-DESC' ? appState.colorTheme.secondary : appState.colorTheme.base, height: filterHeight }} >
                        <span className="icon-cal1" />
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}
              <div className='view-mode-box-container' style={{ height, width: ((appState.itemSize / 3) * 2) - 2 }}>
                <div onClick={() => this.setMode('image')}>
                  <span className="icon-031" style={{ color: mode === 'image' ? appState.colorTheme.secondary : 'black' }} />
                </div>
                <div onClick={() => this.setMode('list')}>
                  <span className="icon-02" style={{ color: mode !== 'image' ? appState.colorTheme.secondary : 'black' }} />
                </div>
              </div>
            </div>
          </div>
          : <div className='info-container' style={{ height: appState.headerItemSize }}>
            <div className='button-mode-container-mobile' style={{ minWidth: appState.headerItemSize }}>
              {/* <div className='row container-option-mobile'>
                <div onClick={() => this.setSelector('name')} >
                  <span className="icon-az" style={{ color: sort.search('name') !== -1 ? appState.colorTheme.secondary : 'black', }} />
                </div>
                <div onClick={() => this.setSelector('cal')} >
                  <span className="icon-cal" style={{ color: sort.search('cal') !== -1 ? appState.colorTheme.secondary : 'black' }} />
                </div>
                {
                  this.state.mobileSelector.length
                    ? <div className='container-option' style={{ top: (appState.headerItemSize / 2) - 2, height: (appState.headerItemSize), width: (appState.headerItemSize) }}>
                      {
                        this.state.mobileSelector === 'name'
                          ? <div className='option-selector'>
                            <div onClick={() => this.setSortMode('name-ASC')} style={{ background: sort === 'name-ASC' ? appState.colorTheme.secondary : appState.colorTheme.base, height }} >
                              <span className="icon-az2" />
                            </div>
                            <div onClick={() => this.setSortMode('name-DESC')} style={{ background: sort === 'name-DESC' ? appState.colorTheme.secondary : appState.colorTheme.base, height }} >
                              <span className="icon-az1" />
                            </div>
                          </div>
                          : null
                      }
                      {
                        this.state.mobileSelector === 'cal'
                          ? <div className='option-selector' style={{ marginLeft: (appState.headerItemSize / 2) }}>
                            <div onClick={() => this.setSortMode('cal-ASC')} style={{ background: sort === 'cal-ASC' ? appState.colorTheme.secondary : appState.colorTheme.base, height }} >
                              <span className="icon-cal2" />
                            </div>
                            <div onClick={() => this.setSortMode('cal-DESC')} style={{ background: sort === 'cal-DESC' ? appState.colorTheme.secondary : appState.colorTheme.base, height }} >
                              <span className="icon-cal1" />
                            </div>
                          </div>
                          : null
                      }
                    </div>
                    : null
                }
              </div> */}
              <div className='row' style={{ borderBottom: 'none' }}>
                <div onClick={() => this.setMode('image')}>
                  <span className="icon-031" style={{ color: mode === 'image' ? appState.colorTheme.secondary : 'black' }} />
                </div>
                <div onClick={() => this.setMode('list')}>
                  <span className="icon-02" style={{ color: mode === 'list' ? appState.colorTheme.secondary : 'black' }} />
                </div>
              </div>
            </div>
            <div className='applied-filters-box-mobile'>
              {
                applied.map((filter, index) => <p onClick={() => this.onDeleteFilter(filter)} key={index} className='delete-applied-filters'>
                  <span className="icon-search-04" style={{ color: appState.colorTheme.secondary }}></span>
                  {filter.tag}
                </p>)
              }
              {
                applied.length
                  ? <p onClick={this.onResetFilters} className='delete-applied-filters'>
                    <span className="icon-search-04"></span>
                    limpiar todos
                  </p>
                  : null
              }
            </div>
          </div>
      }
      <div className='content-container-objects' style={{ maxWidth: appState.itemSize * appState.item }}>
        {
          appState.item === 2
            ? items.map((item, index) => {
              let isFav = item.objectType === 'person'
                ? userAuth.person[item.id]
                : userAuth.objects[item.id]

              let tags = appState.item === 2
                ? item.tags.filter((_, i) => i < 1)
                : item.tags

              for (let filter of applied) {
                let tagExists = tags.find(tag => tag.tag === filter.tag)
                if (filter.type === 'text') break
                if (!tagExists) tags = [filter, ...tags]
                if (filter.tag === 'Cuarto de Proyectos') {
                  let collaborators = item.collaborators.map(collaborator => ({ tag: collaborator }))
                  tags = [...collaborators, ...tags]
                }
              }

              tags = appState.item === 2
                ? tags.filter((_, i) => i < 2)
                : tags

              return <div onClick={() => this.onClickHandler(item)} id={`object-${item.id}`} key={index} className='container-object' style={containerStyles}>
                {
                  mode === 'image'
                    ? this.renderImage(appState.itemSize, item.image, index, item)
                    : null
                }
                <div className={`container-data ${mode !== 'image' ? 'list-mode' : ''}`} style={{ maxWidth: '100%', }}>
                  <div style={styles}>
                    <div className='programs-container'>
                      {
                        tags.map((program, index) => <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className='box-color' style={{ background: appState.colorTheme.level1 }} /> <span key={index} className='text'>{program.tag}</span>
                        </div>)
                      }
                    </div>
                    <div className='title-container'>
                      {
                        item.title.split(' ')
                          .filter((_, i) => i < (appState.item === 2 ? 7 : 10))
                          .map((word, index) => <span key={index} onMouseEnter={this.onMouseEnter} onMouseOut={this.onMouseOut} className={`text ${index === 0 ? (word.length > 10 && appState.item === 2) ? 'big-text-fix' : 'big-text' : ''}`}>{word}</span>)
                      }
                      {
                        item.title.split(' ').length > (appState.item === 2 ? 7 : 10)
                          ? <span className={`text`}>...</span>
                          : null
                      }
                      <div className='programs-container'>
                        <span className='text-tag'>{item.programs[0].program}</span>
                      </div>
                    </div>

                    <div className='date-container'>
                      {
                        item.objectType === 'object'
                          ? <span className='text text-light'>{item.subtitle}</span>
                          : <span className='text text-light' />
                      }
                      {
                        appState.item === 2
                          ? <div className={`option-data-row-mobile`}>
                            <span onClick={e => this.onSetFav(e, item)} className={isFav ? 'icon-heart2' : 'icon-04'} />
                          </div>
                          : null
                      }
                    </div>
                  </div>
                  <div className={`option-data-${mode === 'image' ? 'column' : 'row'}`}>
                    <span onClick={e => this.onSetFav(e, item)} className={isFav ? 'icon-heart2' : 'icon-04'} />
                    <div className='cursor'>
                      <span className='icon-search-03' />
                    </div>
                  </div>
                </div>
              </div>
            })
            : items.map((item, index) => {
              let isFav = item.objectType === 'person'
                ? userAuth.person[item.id]
                : userAuth.objects[item.id]

              let tags = [...item.tags]

              for (let filter of applied) {
                let tagExists = tags.find(tag => tag.tag === filter.tag)
                if (filter.type === 'text') break
                if (!tagExists) tags = [filter, ...tags]
                if (filter.tag === 'Cuarto de Proyectos') {
                  let collaborators = item.collaborators.map(collaborator => ({ tag: collaborator }))
                  tags = [...collaborators, ...tags]
                }
              }

              return <div onClick={() => this.onClickHandler(item)} id={`object-${item.id}`} key={index} className='container-object cursor' style={containerStyles}>
                {
                  mode === 'image'
                    ? this.renderImage(appState.itemSize, item.image, index, item)
                    : null
                }
                <div className={`container-data ${mode !== 'image' ? 'list-mode' : ''}`} style={{ maxWidth: '100%', }}>
                  <div style={styles}>
                    <div className='title-container'>
                      {
                        item.title.split(' ')
                          .filter((_, i) => i < (appState.item === 2 ? 7 : 10))
                          .map((word, index) => <span key={index} onMouseEnter={this.onMouseEnter} onMouseOut={this.onMouseOut} className={`text ${index === 0 ? (word.length > 10 && appState.item === 2) ? 'big-text-fix' : 'big-text' : ''}`}>{word}</span>)
                      }
                      {
                        item.title.split(' ').length > (appState.item === 2 ? 7 : 10)
                          ? <span className={`text`}>...</span>
                          : null
                      }
                    </div>

                    {
                      item.objectType === 'object' &&
                      <div className='title-container'>
                        <p>{item.subtitle}</p>
                      </div>
                    }
                    <div className='date-container'>
                      <div className='programs-container' style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        {
                          tags
                            .filter((_, i) => i < 4)
                            .map((program, index) => <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                              <div className='box-color' style={{ background: appState.colorTheme.level1 }} />
                              <span className='text'>{program.tag} <span style={{ width: '1vw' }} /> {program.year}</span>
                            </div>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`option-data-${mode === 'image' ? 'column' : 'row'}`}>
                    <span onClick={e => this.onSetFav(e, item)} className={isFav ? 'icon-heart2' : 'icon-04'} />
                    <div className='cursor'>
                      <span className='icon-search-03' />
                    </div>
                  </div>
                </div>
              </div>
            })
        }
        {
          this.props.objects.numPages > 1
            ? <Paginator onSearch={this.props.onSearch} />
            : null
        }
        {
          isModalVisible
            ? <Modal>
              <div className='login-modal-container' onClick={() => { this.setState({ isModalVisible: false }) }}>
                <div
                  onClick={e => e.stopPropagation()}
                  className='login-modal'
                  style={{ background: appState.colorTheme.base, width: appState.itemSize * 2, height: appState.itemSize }}
                >
                  <p dangerouslySetInnerHTML={{ __html: texts.register_modal_title }} />
                  <div>
                    <span onClick={() => this.onGoTo(true)}>{texts.register_modal_option_1}</span>
                    <span onClick={() => this.onGoTo()}>{texts.register_modal_option_2}</span>
                  </div>
                </div>
              </div>
            </Modal>
            : null
        }
      </div>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  userAuth: state.userAuth
})
export default connect(mapStateToProps)(Content)
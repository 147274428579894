const text = {
  'menu': {
    'es': {
      home: 'INICIO',
      lecture: 'PROGRAMAS',
      objects: 'ARCHIVO',
      calendar: 'NOTICIAS',
      about: 'NOSOTROS',
      pes: 'PES',
      wednesday: 'MIÉRCOLES',
      summer: 'SUMMER',
      residence: 'RESIDENCIAS'
    },
    'en': {
      home: 'HOME',
      lecture: 'PROGRAMS',
      objects: 'ARCHIVE',
      calendar: 'NEWS',
      about: 'ABOUT',
      pes: 'ACADEMIC',
      wednesday: 'MIÉRCOLES',
      summer: 'SUMMER',
      residence: 'RESIDENCIES'
    }
  },
  'address': {
    'es': {
      addr1: 'Calle 13 #25, Col. San Pedro de los Pinos,',
      addr2: '03800 Ciudad de México',
      contact: 'CONTACTO',
      info: 'SI TIENES OTRA DUDA',
      tellUs: 'PONTE EN CONTACTO CON NOSOTROS'
    },
    'en': {
      addr1: 'Calle 13 #25, Col. San Pedro de los Pinos,',
      addr2: '03800 Mexico city',
      contact: 'CONTACT',
      info: 'IF YOU HAVE ANY QUESTIONS',
      tellUs: 'CONTACT US'
    }
  },
  'register-btn': {
    'es': {
      title: 'REGÍSTRATE',
      subtitle: 'Crea tu propio archivo',
    },
    'en': {
      title: 'Sign up',
      subtitle: 'Create your own archive',
    }
  },
  'my-account-btn': {
    'es': {
      title: 'Mi cuenta',
      subtitle: 'Ver mis archivos',
    },
    'en': {
      title: 'My account',
      subtitle: 'Go to my archive',
    }
  },
  'newsletter': {
    'es': {
      title: 'Suscríbete a nuestro newsletter',
      subtitle: 'y no te pierdas nuestro calendario de eventos',
      sending: 'Enviando...',
      success: '¡Gracias por suscribirte!',
    },
    'en': {
      title: 'Subscribe to our newsletter',
      subtitle: 'and stay updated with our latest news',
      sending: 'Sending...',
      success: 'Thanks for your subscription',
    }
  },
  'about-screen': {
    'es': {
      history: 'Historia',
      title: 'Nosotros',
      annualBtnTitle: 'Reporte Anual',
      annualBtnSubtitle: 'Seleccionar',
      council: 'Consejo',
      support: 'Patronato',
      team: 'Equipo',
    },
    'en': {
      history: 'History',
      title: 'About',
      annualBtnTitle: 'Annual report',
      annualBtnSubtitle: 'Select',
      council: 'COUNCIL',
      support: 'SUPPORT',
      team: 'TEAM',
    }
  },
  'calendar-screen': {
    'es': {
      title: 'Noticias',
      extendedTitle: 'Noticias pasadas',
      filter1: 'Todos',
      filter2: 'Noticias',
      nextWednesday: 'Próximo miércoles',
      noResultTitle: 'SIN RESULTADOS',
      noResultDesc: 'PRUEBA MODIFICANDO ALGUNA PALABRA',
      modalTitle: 'Inicia sesión o registrate',
      modalLogin: 'Iniciar sesión',
      modalSignUp: 'Regístrate',
      pastNews1: 'Noticias',
      nextNews1: 'Noticias',
      pastNews2: 'pasadas',
      nextNews2: 'recientes',
    },
    'en': {
      title: 'News',
      extendedTitle: 'Past news',
      filter1: 'All',
      filter2: 'News',
      nextWednesday: 'NEXT WEDNESDAY',
      noResultTitle: 'NO RESULTS',
      noResultDesc: 'TRY A DIFFERENT WORD',
      modalTitle: 'To save this item please login or sign up',
      modalLogin: 'Login',
      modalSignUp: 'Sign up',
      pastNews1: 'Past',
      nextNews1: 'Recent',
      pastNews2: 'news',
      nextNews2: 'news',
    }
  },
  'PES': {
    'es': {
      title: 'PROGRAMA',
      title2: 'EDUCATIVO',
    },
    'en': {
      title: 'ACADEMIC',
    },
  },
  'object-screen': {
    'es': {
      'person': 'Objetos relacionados',
      'object': 'Colaboradores relacionados',
      'register_modal_title': 'Crea tu propio archivo eligiendo tus registros favoritos. </br>  Accede a tu cuenta para continuar',
      'register_modal_option_1': 'Iniciar sesión',
      'register_modal_option_2': 'Regístrate'
    },
    'en': {
      'person': 'Related objects',
      'object': 'Related collaborators',
      'register_modal_title': 'Select your favorite objects to create your own collection. </br> Log in to your account',
      'register_modal_option_1': 'Log in',
      'register_modal_option_2': 'Sign up'
    }
  },
  'register-screen': {
    'es': {
      instructionsTitle: 'Regístrate y disfruta de los siguientes beneficios:',
      instructionsText1: '- Crea tu propio archivo eligiendo tus registros favoritos.',
      instructionsText2: '- Acceso a contenido exclusivo.',
      instructionsBtn: 'Iniciar',
      requiredFieldText: '* Campo requerido',
      createAccountBtn: 'Crear cuenta',
      nameFieldTitle: '¿Cuál es tu nombre?',
      nameFieldPlaceholder: 'Nombre completo',
      emailFieldTitle: '¿Cuál es tu correo electrónico?',
      emailFieldPlaceholder: 'mail@ejemplo.com',
      pwFieldTitle: 'Ingresa tu contraseña',
      pwFieldPlaceholder: 'Tu contraseña debe tener al menos 6 caracteres',
      pwFieldConfirmTitle: 'Confirma tu contraseña',
      pwFieldConfirmPlaceholder: '',
      yearFieldTitle: 'Elige tu año de nacimiento',
      yearFieldPlaceholder: 'Año',
      genderFieldTitle: 'Elige un género',
      genderFieldPlaceholder: 'Género',
      countryFieldTitle: 'País',
      countryFieldPlaceholder: 'Elige tu país',
      cityFieldTitle: 'Ciudad',
      cityFieldPlaceholder: 'Escribe el nombre de tu ciudad',
      jobFieldTitle: '¿Cuál es tu ocupación?',
      jobFieldPlaceholder: 'Escribe tu ocupación',
      picFieldTitle: 'Sube tu foto de perfil',
      picFieldPlaceholder: 'Elige una foto',
      notRequiredFieldText: '* Campo no requerido',
    },
    'en': {
      instructionsTitle: 'Create your account and enjoy our bennefits',
      instructionsText1: '- Have your favourite objects and make your own archive.',
      instructionsText2: '- Access to exclusive content.',
      instructionsBtn: 'Start',
      requiredFieldText: '* Required field',
      createAccountBtn: 'Create account',
      nameFieldTitle: "What's your name?",
      nameFieldPlaceholder: 'Full name',
      emailFieldTitle: "What's your email?",
      emailFieldPlaceholder: 'mail @example.com',
      pwFieldTitle: 'Password',
      pwFieldPlaceholder: 'Your password must be 6 characters',
      pwFieldConfirmTitle: 'Confirm your password',
      pwFieldConfirmPlaceholder: '',
      yearFieldTitle: 'Select your birthyear',
      yearFieldPlaceholder: 'Year',
      genderFieldTitle: 'Select a gender',
      genderFieldPlaceholder: 'Gender',
      countryFieldTitle: 'Choose your country',
      countryFieldPlaceholder: 'Country',
      cityFieldTitle: 'City',
      cityFieldPlaceholder: 'Write your city name',
      jobFieldTitle: 'Whats your occupation?',
      jobFieldPlaceholder: 'Occupation',
      picFieldTitle: 'Upload a profile picture',
      picFieldPlaceholder: 'No file selected',
      notRequiredFieldText: '* Unrequired field',
    }
  },
  'objects-screen': {
    'es': {
      archiveTitle: 'Archivo',
      archiveSubtitle: 'EXPLORA Y CONÓCELO',
      programTitleBtn: 'Programa',
      activityTitleBtn: 'Actividad',
      personTitleBtn: 'Persona',
      yearTitleBtn: 'Año',
      searchInputPlaceholder: 'Buscar en el Archivo SOMA',
      searchInputPlaceholder2: 'Buscar en el Archivo',
      infoTextDesc: 'Esto te puede interesar',
      quickSearchBtn: 'sorpréndeme',
      searchFilterBtn: 'Buscar',

    },
    'en': {
      archiveTitle: 'Archive',
      archiveSubtitle: 'EXPLORE IT',
      programTitleBtn: 'Program',
      activityTitleBtn: 'Activity',
      personTitleBtn: 'Role',
      yearTitleBtn: 'Year',
      searchInputPlaceholder: 'Search in the SOMA archive',
      searchInputPlaceholder2: 'Search in the archive',
      infoTextDesc: 'You may also like',
      quickSearchBtn: 'Surprise me',
      searchFilterBtn: 'Search',
    },
  },
  'wednesday-screen': {
    'es': {
      archiveTitle: 'Archivo',
    },
    'en': {
      archiveTitle: 'Archive',
    },
  },
  'residences-screen': {
    'es': {
      archiveTitle: 'Archivo',
      residence: 'RESIDENCIAS'
    },
    'en': {
      archiveTitle: 'Archive',
      residence: 'RESIDENCIES'
    },
  },
  'login-screen': {
    'es': {
      emailFieldPlaceholder: 'Correo electrónico',
      pwFieldPlaceholder: 'Contraseña',
      logInBtn: 'Iniciar sesión',
      registerBtn: 'Registrarme',
      forgotPwBtn: '¿Olvidaste tu contraseña?',
      pwRecoveryTitle: '¡Listo!',
      pwRecoverySubtitle: 'Revisa tu correo electrónico',
      pwRecoveryGoBackBtn: 'Regresar',
      pwRecoveryRequestTitle: 'Recupera tu contraseña',
      pwRecoveryRequestSubtitle: 'Introduce tu correo electrónico',
      pwRecoveryRequestSendBtn: 'Enviar',
      wrongCredentialsMsg: 'El usuario o password es incorrecto',
    },
    'en': {
      emailFieldPlaceholder: 'Email',
      pwFieldPlaceholder: 'Password',
      logInBtn: 'Login',
      registerBtn: 'Sign Up',
      forgotPwBtn: 'Forgot your password?',
      pwRecoveryTitle: '¡Done!',
      pwRecoverySubtitle: 'Please check your email',
      pwRecoveryGoBackBtn: 'Back',
      pwRecoveryRequestTitle: 'Recover your password',
      pwRecoveryRequestSubtitle: 'Write your email',
      pwRecoveryRequestSendBtn: 'Send',
      wrongCredentialsMsg: 'Your mail or password are incorrect'
    }
  },
  'my-account': {
    'es': {
      'title': 'Mi Cuenta',
      'all_option': 'TODOS',
      'location': 'Locación',
      'profession': 'Profesión',
      'edit_profile': 'Editar perfil',
      'extended_videos': 'Videos extendidos',
      'activities': 'Actividades',
      'followed_artists': 'Artistas seguidos',
      'cancel': 'Cancelar',
      'save': 'Guardar',
      'hello': 'Hola',
      'emtyDataTitle': 'COMIENZA A GUARDAR EL CONTENIDO QUE TE INTERESA',
      'emptyDataDesc': 'Cuando veas este icono significa que puedes guardar el contenido para revisarlo después',
      'see_more': 'Ver más',
      'artist_residence': 'Artistas en residencia',
      'news': 'Noticias',
      'explore_archive': 'Explora el archivo',
      'explore_events': 'Explora los eventos',
      'wednesday': 'Conoce miércoles de SOMA',
      'featured': 'Destacado',
    },
    'en': {
      'title': 'My Account',
      'all_option': 'ALL',
      'location': 'Location',
      'profession': 'Profession',
      'edit_profile': 'Edit profile',
      'extended_videos': 'Extended videos',
      'activities': 'Activities',
      'followed_artists': 'Followed artists',
      'cancel': 'Cancel',
      'save': 'Save',
      'hello': 'Hi',
      'emtyDataTitle': 'START SAVING THE CONTENT THAT INTERESTS YOU',
      'emptyDataDesc': 'When you see this icon it means you can save the content to watch it later',
      'see_more': 'See more',
      'artist_residence': 'Artists in residence',
      'news': 'News',
      'explore_archive': 'Explore the archive',
      'explore_events': 'Explore the events',
      'wednesday': 'Miércoles de SOMA',
      'featured': 'Featured',
    },
  }
}

const getTexts = (screen, lang = 'es') => {
  return text[screen][lang]
}

export default getTexts